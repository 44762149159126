import { Selector, useSelector as useReduxSelector } from 'react-redux';
import { createSlice, Reducer } from '@reduxjs/toolkit';
import { CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

function createSelectorForState<S>(index: string) {
  return function useSelector<T>(selector: Selector<S, T>, equalityFn?: (left: T, right: T) => boolean) {
    return useReduxSelector((state: any) => selector(state[index] as S), equalityFn);
  };
}

export function createReduxState<S, T extends SliceCaseReducers<S>>(options: CreateSliceOptions<S, T>) {
  const { reducer, actions } = createSlice(options);
  const reducerMap: { [key: string]: Reducer<S> } = {};
  reducerMap[options.name] = reducer;
  return {
    reducers: () => ({ id: options.name, reducerMap }),
    actions,
    useSelector: createSelectorForState<S>(options.name),
  };
}
