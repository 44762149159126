import React, { FC, useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import AppStore from 'Libs/Store';
import Global from 'Styles/global';
import Loading from 'Interface/Loading';
import startup from 'Configs/startup';
import Core from 'Modules/core';

const App: FC = () => {
  const [ready, setReady] = useState<boolean>(false);
  const store = AppStore.createStore();

  useEffect(() => {
    startup(() => setReady(true));
  }, []);

  if (!ready) {
    return <Loading light transparent />;
  }

  return (
    <Provider store={store}>
      <Global />
      <BrowserRouter>
        <Core />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
