import styled, { css } from 'styled-components';

import { colors } from 'Styles/theme';

export const Root = styled(({ size, ...props }) => <div {...props} />)<{
  size: number;
}>`
  ${({ size, onClick }) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size / 2}px;
    background: ${colors.grey3};
    font-size: ${(size / 10) * 1}rem;
    text-align: center;
    background-size: cover;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
    line-height: normal;
    overflow: hidden;
    cursor: ${onClick ? 'pointer' : 'default'};
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: ${onClick ? 0.7 : 1};
    }
  `}
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const User = styled.div`
  background: ${colors.grey1};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
