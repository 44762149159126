import { has } from 'lodash';

import { IFilter } from './FilterMenu.types';

export const toggleFilter = (filter: Record<string, any>, appliedFilter: Record<string, any>) => {
  const { domain, value } = filter;
  const hasDomain = has(appliedFilter, domain);
  if (hasDomain) {
    const hasValue = appliedFilter[domain].includes(value);
    if (!hasValue) {
      return { ...appliedFilter, [domain]: [...appliedFilter[domain], value] };
    }
    return { ...appliedFilter, [domain]: appliedFilter[domain].filter((f: any) => f !== value) };
  }
  return { ...appliedFilter, [domain]: [value] };
};

export const getFilterTags = (filters: IFilter[], filtersApplied: Record<string, any>) =>
  Object.keys(filtersApplied)
    .map((k: string) => {
      const domainItems = filters.find((f: IFilter) => f.domain === k)?.items.map((it: any) => ({ ...it, domain: k }));
      return (domainItems ?? []).filter((it) => filtersApplied[k].includes(it.value));
    })
    .flat();
