import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import { PRELOADING_DELAY } from 'Constants/app';

import Loading from 'Interface/Loading';

import loginReducers from 'Modules/login/reducers';
import signUpReducers from 'Modules/signup/reducers';
import reducers from './reducers';

const Invites = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light transparent />,
});

export default () => (
  <DynamicModuleLoader modules={[reducers(), loginReducers(), signUpReducers()]}>
    <Invites />
  </DynamicModuleLoader>
);
