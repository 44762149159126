import styled, { css } from 'styled-components';
import { colors } from 'Styles/theme';
import { R12 } from 'Styles/Typography';
import { BadgePlacements, IBadgeProps } from './Badge.types';

export const Container = styled.span`
  display: inline-block;
  position: relative;

  > svg {
    display: block;
  }
`;

export const Count = styled(R12('sup'))`
  color: white;
`;

export const Badge = styled.div<{ show?: boolean; placement?: IBadgeProps['placement'] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 20px;
  height: 20px;
  border-radius: 20px;
  background: ${colors.red4};
  border: 1px solid white;
  overflow: hidden;
  transform: ${({ show }) => (show ? 'scale(1)' : 'scale(0)')};
  transition: all 0.25s cubic-bezier(0.52, 0.4, 0.28, 1.24);
  padding: 0 6px;

  ${({ placement }) => {
    switch (placement) {
      case BadgePlacements.bottomRight:
        return css`
          bottom: -6px;
          left: 100%;
          margin-left: -13px;
        `;
      default:
        return css`
          top: -6px;
          left: 100%;
          margin-left: -13px;
        `;
    }
  }}
`;
