import { Col } from 'react-styled-flexboxgrid';
import NotificationsCenter from './NotificationsCenter';
import UserProfile from './UserProfile';
import * as S from './RightSection.styles';

export default function RightSection() {
  return (
    <Col xs={2} sm={3} style={{ display: 'flex' }}>
      <S.Root>
        <NotificationsCenter />
        <UserProfile />
      </S.Root>
    </Col>
  );
}
