import DropDown from 'Interface/DropDown';
import BellIcon from 'Assets/svg/icons/bell.svg';
import Badge from 'Interface/Badge';
import NotificationsList from './NotificationsList';
import * as S from './NotificationsCenter.styles';
import useNotificationCenter from './useNotificationsCenter';

export default function NotificationCenter() {
  const { mobile, tablet, unseen, ready, notifyNotificationsSeen } = useNotificationCenter();

  const renderNotificationsList = () => {
    if (ready) {
      return <NotificationsList scrollableTarget="notifications-list" />;
    }
  };

  const renderDropDown = (target: JSX.Element) => (
    <DropDown
      id="notifications-list"
      dataComponent={renderNotificationsList()}
      placement="bottom-end"
      closeOnClick={false}
      onOpen={() => notifyNotificationsSeen()}
      dropDownStyle={S.dropDownStyle}
      offset={[200, 0]}
    >
      {target}
    </DropDown>
  );

  const renderFullScreen = (target: JSX.Element) => (
    <DropDown
      id="notifications-list"
      dataComponent={renderNotificationsList()}
      placement="bottom-start"
      onOpen={() => notifyNotificationsSeen()}
      dropDownStyle={S.mobileStyle}
    >
      {target}
    </DropDown>
  );

  const renderOverlay = (target: JSX.Element) => (
    <DropDown
      id="notifications-list"
      showOverlay
      dataComponent={renderNotificationsList()}
      placement="bottom-end"
      closeOnClick={false}
      onOpen={() => notifyNotificationsSeen()}
      dropDownStyle={S.tabletStyle}
      offset={[200, 4]}
    >
      {target}
    </DropDown>
  );

  const target = (
    <S.Button>
      <Badge count={unseen}>
        <BellIcon height={24} />
      </Badge>
    </S.Button>
  );

  if (mobile) {
    return renderFullScreen(target);
  }
  if (tablet) {
    return renderOverlay(target);
  }
  return renderDropDown(target);
}
