import { T } from 'Configs/i18n';

import * as S from './Filter.styles';
import { IFilter, IFilterItem } from '../FilterMenu.types';

type IFilterProps = {
  filter: IFilter;
  onClickFilter: (value: Record<string, any>) => void;
  selected: Record<string, any>;
  expanded: boolean;
  toggleExpand: (dom: string) => void;
};

const ITEMS_COUNT = 8;

function Filter(props: IFilterProps) {
  const { filter, onClickFilter, selected = {}, expanded, toggleExpand } = props;
  const { title, domain, items } = filter;

  const renderItems = (limit: number) => {
    const filterItems = limit === 0 ? items : items.slice(0, limit);
    return filterItems.map((fi: IFilterItem) => {
      const { label, value } = fi;
      const isSelected = selected[domain] ? selected[domain].includes(value) : false;
      return (
        <S.FilterItem key={value as string} onClick={() => onClickFilter({ domain, value })} selected={isSelected}>
          {label}
        </S.FilterItem>
      );
    });
  };

  return (
    <S.Root>
      <S.Title>{title}</S.Title>
      <S.Items>{expanded ? renderItems(0) : renderItems(ITEMS_COUNT)}</S.Items>
      {items.length > ITEMS_COUNT && (
        <S.ViewAll onClick={() => toggleExpand(domain)}>
          {expanded ? <T>global.hide_all</T> : <T>global.view_all</T>}
        </S.ViewAll>
      )}
    </S.Root>
  );
}

export default Filter;
