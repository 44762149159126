import { Component, ErrorInfo, ReactNode } from 'react';
import Loading from 'Interface/Loading';

type IErrorBoundaryProps = {
  children: ReactNode;
};

type IErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
};

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  // eslint-disable-next-line class-methods-use-this
  tryToReload = () => {
    setTimeout(() => window.location.reload(), 1000);
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      this.tryToReload();
      return <Loading light />;
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
