const palette = ['#719fc6', '#3be1c8', '#febb9a', '#edd4be', '#97dbfb', '#ffc366', '#ff9494'];
const getColorByLetter = () => {
  const colors: Record<string, string> = {};
  for (let i = 0; i <= 25; i += 1) {
    colors[String.fromCharCode(i + 65)] = palette[i % palette.length];
  }
  return colors;
};

export const getRandomColor = () => palette[Math.floor(Math.random() * (palette.length - 1))];

export const getColor = (text: string) => getColorByLetter()[text[0]];

const convertRange = (value: number, r1: number[], r2: number[]) => {
  const range = ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
  return Math.ceil(Math.min(Math.max(range, r2[0]), r2[1]));
};

export const placeholderHash = (str: string, range: number[] = [1, 4]) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }

  return convertRange(hash, [0, 1500000000], range);
};
