export default {
  groupsHome() {
    return '/groups';
  },

  groupDetails(groupId: string) {
    return `/groups/${groupId}`;
  },

  groupAdmins(groupId: string) {
    return `/groups/${groupId}/settings/members?filter=ADMINS`;
  },

  groupInvites(groupId: string) {
    return `/groups/${groupId}/settings/members?filter=INVITED`;
  },

  groupInviteDetails(groupId: string, inviteId: string) {
    return `/groups/${groupId}/members/invites/${inviteId}`;
  },

  groupRequests(groupId: string) {
    return `/groups/${groupId}/requests`;
  },

  groupSettings(groupId: string) {
    return `/groups/${groupId}/settings`;
  },

  groupPosts(groupId: string) {
    return `/groups/${groupId}/posts`;
  },

  groupEvents(groupId: string) {
    return `/groups/${groupId}/events`;
  },

  groupAbout(groupId: string) {
    return `/groups/${groupId}/about`;
  },

  groupMembershipRequests(groupId: string) {
    return `/groups/${groupId}/settings/membership-requests`;
  },

  groupMembershipDetails(groupId: string, requestId: string) {
    return `/groups/${groupId}/settings/membership-requests/${requestId}`;
  },

  groupMembershipRequestsApproved(groupId: string) {
    return `/groups/${groupId}/settings/membership-requests?filter=APPROVED`;
  },

  groupMembershipRequestsDeclined(groupId: string) {
    return `/groups/${groupId}/settings/membership-requests?filter=DECLINED`;
  },

  groupMembers(groupId: string) {
    return `/groups/${groupId}/settings/members`;
  },

  groupMember(groupId: string, memberId: string) {
    return `/groups/${groupId}/member/${memberId}`;
  },

  groupMemberInvited(groupId: string, inviteId: string) {
    return `/groups/${groupId}/invited/${inviteId}`;
  },
};
