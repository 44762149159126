import { TITLE_BASE, TITLE_SEPARATOR } from 'Constants/app';
import { capitalize, uniq } from 'lodash';
import { i18n } from 'Configs/i18n';

export function getTitle(titles: (string | undefined)[] = []) {
  return `${[TITLE_BASE, ...titles.filter((t) => t)].reverse().join(TITLE_SEPARATOR)} - ${process.env.VERSION}`;
}

export function parseLocationLabel(location: any) {
  const string = [];

  if (!location) {
    return '';
  }

  let road;
  if (location.custom) {
    return location.formattedAddress;
  }

  const city = location.city || location.town || location.village;
  // let cityDistrict = addr.city_district || addr.suburb;
  const { county } = location;
  const { state } = location;
  const { country } = location;
  const { name } = location;

  if (location.road || location.pedestrian) {
    road = location.road || location.pedestrian;
    if (location.house_number) {
      road += ` ${location.house_number}`;
    }
  }

  if (road && !location.number) {
    string.push(road);
  } else if (road && location.number) {
    string.push(`${road} ${location.number}`);
  } else if (!road && location.number) {
    string.push(location.number);
  }
  // if (cityDistrict) {
  //   string.push(cityDistrict);
  // }
  if (city) {
    string.push(city);
  }
  if (county && county !== city) {
    string.push(county);
  }
  if (state) {
    string.push(state);
  }
  if (country) {
    string.push(country);
  }
  if (string.indexOf(name) === -1) {
    string.unshift(name);
  }

  return (uniq(string.filter((str) => str !== null)).join(', ') || '')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export function formatPermissions(canRequest: boolean | undefined, canContribute: boolean | undefined) {
  if (canRequest && canContribute) {
    return i18n.__('global.can_request_can_contribute');
  }
  if (canRequest && !canContribute) {
    return i18n.__('global.can_request');
  }
  if (canContribute && !canRequest) {
    return i18n.__('global.can_contribute');
  }
  return '-';
}
