import { PayloadAction } from '@reduxjs/toolkit';
import { createReduxState } from 'Libs/helpers';

import { toggleFilter } from 'Interface/FilterMenu';

import { IUnitsState } from './types';

const initialState: IUnitsState = {
  keyword: '',
  total: 0,
  unitMembersFilter: {},
};

export const {
  reducers,
  actions,
  useSelector: useUnitsSelector,
} = createReduxState({
  name: 'units',
  initialState,
  reducers: {
    setKeyword(state, { payload: keyword }: PayloadAction<string>) {
      state.keyword = keyword;
    },
    setTotal(state, { payload: total }: PayloadAction<number>) {
      state.total = total;
    },
    setUnitMembersFilter(state, { payload: filter }: PayloadAction<Record<string, any>>) {
      const newFilter = toggleFilter(filter, state.unitMembersFilter);
      state.unitMembersFilter = newFilter;
    },
    resetUnitMembersFilter() {
      return { ...initialState };
    },
  },
});
