import { createSlice } from '@reduxjs/toolkit';
import { ReactElement, isValidElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { INotificationHideAction, INotificationMessage, INotificationShowAction, INotificationState } from '../types';

export const DEFAULT_LABEL = 'OK';

export const initialState: INotificationState = {
  messages: [],
};

const { reducer, actions } = createSlice({
  name: 'notificationBar',
  initialState,
  reducers: {
    showNotificationBar(state, { payload }: INotificationShowAction) {
      let message;

      switch (typeof payload) {
        case 'object':
          if (isValidElement(payload)) {
            message = { message: payload as ReactElement };
          } else {
            message = { ...payload } as INotificationMessage;
          }
          break;
        default:
          message = { message: payload as ReactElement };
      }

      state.messages = [
        ...state.messages,
        {
          visible: true,
          ...message,
          id: uuidv4(),
        },
      ];
    },
    hideNotificationBar(state, { payload }: INotificationHideAction) {
      if (payload && payload.length) {
        state.messages = [
          ...state.messages.map((message) => {
            if (payload.includes(message.id as string)) {
              return {
                ...message,
                visible: false,
              };
            }
            return message;
          }),
        ];
      }
    },
    removeNotificationBar(state, { payload }: INotificationHideAction) {
      if (payload && payload.length) {
        const messages = [...state.messages.filter((message) => !payload.includes(message.id as string))];
        state.messages = messages;
      }
    },
  },
});

export { actions };
export default reducer;

/* export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Actions.SHOW_NOTIFICATION_BAR:
      if (typeof action.message === 'object') {
        return {
          visible: true,
          label: DEFAULT_LABEL,
          ...action.message,
        };
      }
      return {
        visible: true,
        message: action.message,
        label: action.label || DEFAULT_LABEL,
      };

    case Actions.HIDE_NOTIFICATION_BAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}; */
