/* eslint-disable @typescript-eslint/no-explicit-any,no-multi-assign,no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { FlattenSimpleInterpolation } from 'styled-components';
import { parse } from 'Libs/css';

//
// Transform implementation or originally thanks to
// https://github.com/raphamorim/native-css
//

type StyleRuleExtra = {
  media?: string;
  rules?: LocalStyleRules;
  declarations?: any[];
  selectors?: any[];
  type: string;
};

type LocalStyleRules = Array<StyleRuleExtra>;

function transformRules(rules: LocalStyleRules | undefined, result: Record<string, any>) {
  for (const rule of rules ?? []) {
    const obj: Record<string, any> = {};
    if (rule.type === 'media' && rule.media) {
      const name = mediaNameGenerator(rule.media);
      const media = (result[name] = result[name] || {
        __expression__: rule.media,
      });
      transformRules(rule.rules, media);
    } else if (rule.type === 'rule') {
      for (const declaration of rule.declarations ?? []) {
        if (declaration.type === 'declaration') {
          const cleanProperty = cleanPropertyName(declaration.property);
          obj[cleanProperty] = declaration.value;
        }
      }
      for (const selector of rule.selectors ?? []) {
        const name = nameGenerator(selector.trim());
        result[name] = obj;
      }
    }
  }
}

function cleanPropertyName(name: string) {
  // turn things like 'align-items' into 'alignItems'
  return name.replace(/(-.)/g, (v) => v[1].toUpperCase());
}

function mediaNameGenerator(name: string) {
  return `@media ${name}`;
}

function nameGenerator(src: string) {
  let name = src.replace(/\s\s+/g, ' ');
  name = name.replace(/[^a-zA-Z0-9]/g, '_');
  name = name.replace(/^_+/g, '');
  name = name.replace(/_+$/g, '');
  return name;
}

export default function transform(text: FlattenSimpleInterpolation) {
  if (!text) {
    throw new Error('missing css text to transform');
  }

  // If the input "css" doesn't wrap it with a css class (raw styles)
  // we need to wrap it with a style so the css parser doesn't choke.
  let bootstrapWithCssClass = false;
  let style: string = text.toString();
  if (style.indexOf('{') === -1) {
    bootstrapWithCssClass = true;
    style = `.bootstrapWithCssClass { ${style} }`;
  }

  const ast = parse(style);
  let result = {};
  transformRules(ast.stylesheet?.rules, result);

  // Don't expose the implementation detail of our wrapped css class.
  if (bootstrapWithCssClass) {
    result = (result as { bootstrapWithCssClass: any }).bootstrapWithCssClass;
  }
  return result;
}
