import { FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './NavButton.styles';

type INavButtonProps = {
  label: string;
  to: string;
  icon: ReactElement;
  activeIcon: ReactElement;
};

const NavButton: FC<INavButtonProps> = (props) => {
  const { children, label, to, icon, activeIcon } = props;
  const location = useLocation();
  const isPathActive = (path: string) => Boolean(location.pathname.indexOf(path) > -1 ? 1 : 0);
  const active = isPathActive(to);

  return (
    <S.Button active={active} to={to}>
      {active ? activeIcon : icon}
      {children}
      <S.Label>{label}</S.Label>
    </S.Button>
  );
};

export default NavButton;
