import * as colors from './colors';
import * as breakpoints from './breakpoints';
import { space } from './spacing';

export { colors, space, breakpoints };

export const fonts = {
  bold: "'MessinaSans-SemiBold', Arial, 'Helvetica Neue', Helvetica, sans-serif",
  regular: "'MessinaSans-Regular', Arial, 'Helvetica Neue', Helvetica, sans-serif",
  px44: { size: '2.75rem', height: '3rem' }, // 44px
  px32: { size: '2rem', height: '2.25rem' }, // 32px
  px24: { size: '1.5rem', height: '1.75rem' }, // 24px
  px20: { size: '1.25rem', height: '1.5rem' }, // 20px
  px18: { size: '1.125rem', height: '1.375rem' }, // 18px
  px16: { size: '1rem', height: '1.25rem' }, // 16px
  px15: { size: '0.9375rem', height: '1.125rem' }, // 15px
  px14: { size: '0.875rem', height: '1.125rem' }, // 14px
  px13: { size: '0.8125rem', height: '1rem' }, // 13px
  px12: { size: '0.75rem', height: '1rem' }, // 12px
  body: { size: '1rem', height: '1.375rem' }, // 16px
};

// Define typography for multiple break points
// @media breakpoints max-width: [default, lg, md, sm], false value can be used to skip
// see Typography.Style for breakpoints
// typography names based on https://bbc.github.io/gel-typography-v2/v2
export const theme = {
  b24: {
    tag: 'h1',
    $fontSize: [fonts.px24.size, fonts.px20.size],
    $lineHeight: [fonts.px24.height, fonts.px24.height],
    $fontFamily: fonts.bold,
  },
  b20: {
    tag: 'h2',
    $fontSize: [fonts.px20.size, fonts.px18.size],
    $lineHeight: [fonts.px20.height, fonts.px18.height],
    $fontFamily: fonts.bold,
  },
  b16: {
    tag: 'h3',
    $fontSize: fonts.px16.size,
    $lineHeight: fonts.px16.height,
    $fontFamily: fonts.bold,
  },
  b14: {
    $fontSize: [fonts.px14.size, false, false, fonts.px15.size],
    $lineHeight: [fonts.px14.height, false, false, fonts.px15.height],
    $fontFamily: fonts.bold,
  },
  b12: {
    $fontSize: fonts.px12.size,
    $lineHeight: fonts.px12.height,
    $fontFamily: fonts.bold,
  },
  r16: {
    $fontFamily: fonts.regular,
    $fontSize: fonts.body.size,
    $lineHeight: fonts.body.height,
  },
  r14: {
    $fontFamily: fonts.regular,
    $fontSize: fonts.px14.size,
    $lineHeight: fonts.px14.height,
  },
  r13: {
    $fontSize: [fonts.px13.size, false, false, fonts.px14.size],
    $lineHeight: [fonts.px13.height, false, false, fonts.px14.height],
    $fontFamily: fonts.regular,
  },
  r12: {
    $fontSize: fonts.px12.size,
    $lineHeight: fonts.px12.height,
    $fontFamily: fonts.regular,
  },
};
