import { PropsWithChildren } from 'react';
import { isArray } from 'lodash';

import DropDown, { closeDropDown } from 'Interface/DropDown';

import * as S from './DropDownMenu.styles';
import { IDropDownMenu, IMenuItem } from './DropDownMenu.types';

export function conditionalMenuItem(cond: boolean, item: IMenuItem | IMenuItem[]) {
  if (cond) {
    if (isArray(item)) {
      return item;
    }
    return [item];
  }
  return [];
}

export default function DropDownMenu(props: PropsWithChildren<IDropDownMenu>) {
  const { children, placement = 'bottom-start', offset, ...rest } = props;

  const renderDataItem = (item: IMenuItem, i: number) => {
    const { label, icon, action, divider, className } = item;
    if (divider) {
      return <S.Divider key={i} />;
    }
    return (
      <S.Item key={i} onClick={closeDropDown(action)} className={className}>
        <S.Content>
          {icon && <S.Icon>{icon}</S.Icon>}
          <S.Label>{label}</S.Label>
        </S.Content>
      </S.Item>
    );
  };

  return (
    <DropDown
      arrow
      offset={offset ?? [0, 8]}
      closeOnClick={false}
      dataItem={renderDataItem}
      dropDownStyle={S.DropDownStyle}
      placement={placement}
      {...rest}
    >
      {children}
    </DropDown>
  );
}
