import CssToObject from 'Libs/CssToObject';
import styled, { css } from 'styled-components';

import { colors, space } from 'Styles/theme';

export const DropDownStyle = CssToObject(css`
  border: 0 none;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`);

export const Root = styled.div`
  max-width: 400px;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: ${space.px24} ${space.px20};
  overflow: auto;

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const Buttons = styled.div`
  border-top: 1px solid ${colors.grey0};
  padding: ${space.px12} ${space.px16};

  & > button:nth-child(2) {
    margin-left: ${space.px8};
  }
`;

export const Badge = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 10px;
  font-family: 'MessinaSans-SemiBold';
  background: white;
  color: ${colors.blue10};
  margin-left: 6px;
`;
