import {get} from 'lodash';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isAlphaEnv, isLocalEnv, isStagingEnv } from 'Libs/helpers';

import Meteor, { Accounts, shouldLogMeteor } from 'API/Meteor';

// import Typing from 'Libs/Typing';

export default (callback) => {
  if (!isLocalEnv()) {
    if (isStagingEnv()) {
      Sentry.init({ dsn: 'https://9ef80c8963ee4ed69815d4324fb4d064@sentry.io/1192741' });
    } else if (isAlphaEnv()) {
      Sentry.init({
        dsn: 'https://c98cc7f41cec43b7ab066bd72d485faa@o92192.ingest.sentry.io/6050754',
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    } else {
      Sentry.init({ dsn: 'https://2eff46fddf2e48eb83027614225547d5@sentry.io/1192736' });
    }
    Sentry.configureScope((scope) => {
      scope.setTag('app_version', process.env.VERSION);
    });
  }

  if (shouldLogMeteor()) {
    Meteor.enableVerbose();
  }

  Meteor.connect(process.env.METEOR_HOST);

  Accounts.onLogin(() => {
    const user = Meteor.user();

    if (user) {
      // const idle = new UserIdleStatus(user);
      // idle.start.bind(idle);

      Sentry.configureScope((scope) => {
        scope.setUser({
          userID: get(user, '_id'),
        });
      });
    }

    // Typing.listen();
  });

  Meteor.waitDdpConnected(() => {
    return callback();
  });
};
