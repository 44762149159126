import { IModule } from 'redux-dynamic-modules';
import { createSlice } from '@reduxjs/toolkit';
import { Selector, useSelector as useReduxSelector } from 'react-redux';

import { detectCountryCode } from 'Libs/helpers';

import { ILoginState, ILoginModuleState, ILoginStringAction, ILoginBooleanAction } from './types';

export const initialState: ILoginState = {
  countryCode: detectCountryCode(),
  phoneNumber: '',
  newUser: false,
};

const { reducer, actions } = createSlice({
  name: 'login',
  initialState,
  reducers: {
    countryCode(state, { payload: countryCode }: ILoginStringAction) {
      state.countryCode = countryCode;
    },
    phoneNumber(state, { payload: phoneNumber }: ILoginStringAction) {
      state.phoneNumber = phoneNumber;
    },
    newUser(state, { payload: newUser }: ILoginBooleanAction) {
      state.newUser = newUser;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export { actions };

export function useSelector<T>(selector: Selector<ILoginModuleState, T>) {
  return useReduxSelector<ILoginModuleState, T>(selector);
}

export default () =>
  ({
    id: 'login',
    reducerMap: {
      login: reducer,
      // newPost ???
    },
  } as IModule<ILoginModuleState>);
