import { PropsWithChildren } from 'react';
import { IBadgeProps } from './Badge.types';
import * as S from './Badge.styles';

export default function Badge(props: PropsWithChildren<IBadgeProps>) {
  const { children, count, showZero = false, placement, visible = true, ...restProps } = props;
  return (
    <S.Container {...restProps}>
      {children}
      <S.Badge show={visible && !!(count || showZero)} placement={placement}>
        <S.Count>{count}</S.Count>
      </S.Badge>
    </S.Container>
  );
}
