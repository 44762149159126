import { createReduxState } from 'Libs/helpers';
import { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { IBucketsState } from '../types';

export const initialState: IBucketsState = {
  values: {},
};

const { reducers, actions, useSelector } = createReduxState({
  name: 'buckets',
  initialState,
  reducers: {
    push(state, { payload }: PayloadAction<{ id: string; value: any }>) {
      state.values[payload.id] = payload.value;
    },
    pop(state, { payload: id }: PayloadAction<string>) {
      delete state.values[id];
    },
    clear() {
      return { values: {} };
    },
  },
});

export function useBuckets() {
  return useSelector((state) => state.values);
}

export { actions };
const r: { reducerMap: { buckets: Reducer<IBucketsState> } } = reducers() as any;
export default r.reducerMap.buckets;
