import { useMemo } from 'react';

import { T } from 'Configs/i18n';

import DropDown, { closeDropDown } from 'Interface/DropDown';
import Button, { ButtonTypes } from 'Interface/Button';

import FilterIcon from 'Assets/svg/icons/filter.svg';

import { IFilter, IFilterMenuProps, IExpanded } from './FilterMenu.types';
import * as S from './FilterMenu.styles';
import Filter from './Filter';
import useFilterMenu from './useFilterMenu';

function FilterMenu(props: IFilterMenuProps) {
  const { expanded, toggleExpand } = useFilterMenu();
  const { onClickFilter, onClear, onDone, filters, selected } = props;
  const filterCount = Object.values(selected).flat().length;

  const renderButtons = () => (
    <S.Buttons>
      <Button type={ButtonTypes['secondary-alt']} onClick={onClear} disabled={!filterCount}>
        <T>global.clear</T>
      </Button>
      <Button onClick={closeDropDown(onDone)}>
        <T>global.done</T>
      </Button>
    </S.Buttons>
  );

  const renderFilter = (f: IFilter, i: number) => {
    const exp = expanded.filter((e: IExpanded) => e.domain === f.domain);
    return (
      <Filter
        filter={f}
        key={i}
        onClickFilter={onClickFilter}
        selected={selected}
        expanded={exp[0].expanded}
        toggleExpand={toggleExpand}
      />
    );
  };

  const renderFilters = () => filters.map(renderFilter);

  const renderDropDown = useMemo(
    () => (
      <S.Root>
        <S.Content>{renderFilters()}</S.Content>
        {renderButtons()}
      </S.Root>
    ),
    [selected, expanded]
  );

  return (
    <DropDown
      arrow
      offset={[0, 8]}
      closeOnClick={false}
      dataComponent={renderDropDown}
      dropDownStyle={S.DropDownStyle}
      placement="bottom"
      data={filters}
    >
      <Button type={filterCount > 0 ? ButtonTypes.action : ButtonTypes.secondary} icon={<FilterIcon width={18} />}>
        <T>global.filter</T>
        {filterCount > 0 && <S.Badge>{filterCount}</S.Badge>}
      </Button>
    </DropDown>
  );
}

export default FilterMenu;
