import loadable from '@loadable/component';
import Loading from 'Interface/Loading';
import pMinDelay from 'p-min-delay';

import { PRELOADING_DELAY } from 'Constants/app';

const Download = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light transparent />,
});

export default () => <Download />;
