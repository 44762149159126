import Meteor, { deleteUserToken, MeteorCall, useTracker } from 'API/Meteor';

import { i18n } from 'Configs/i18n';

export default function useCore() {
  const nonAdminRedirect = () => {
    setTimeout(() => {
      MeteorCall('logout');
      deleteUserToken();
    }, 1000);
  };

  return useTracker(() => {
    let data = {
      loading: true,
      user: null,
      isAdmin: false,
    };

    const storedUserId = localStorage.getItem('Meteor.userId');
    const storedLoginToken = localStorage.getItem('Meteor.loginToken');
    const user = Meteor.user();
    const isAdmin = user ? user.showMembersTab : false;

    if (user) {
      data = {
        loading: false,
        user,
        isAdmin,
      };
      i18n.changeLocale(user.locale);
    } else if (!user && (!storedUserId || !storedLoginToken)) {
      data = {
        loading: false,
        user: null,
        isAdmin,
      };
    }

    if (user && !isAdmin) {
      nonAdminRedirect();
    }

    return data;
  });
}
