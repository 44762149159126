export function enumKeys(target: Record<string, unknown>): string[] {
  const { length } = Object.keys(target);
  return Object.keys(target).slice(length / 2, length);
}

export function enumValues(target: Record<string, unknown>) {
  const { length } = Object.keys(target);
  return Object.values(target).slice(length / 2, length);
}

export function getEnumKey<T>(e: T, v: any): keyof T {
  const i = Object.values(e).indexOf(v);
  return Object.keys(e)[i] as keyof T;
}
