import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'Hooks/useModal';

import { MeteorCall } from 'API/Meteor';
import { INotification } from 'API/types/INotification';

import { usePagination } from 'Hooks/usePagination';

import { ICoreModuleState } from 'Modules/core/types';
import { updatedBadgesAt } from 'Modules/core/actions';
import NotSupported from 'Dialogs/NotSupported';
import NybyUri from 'API/NybyUri';

export default function useNotificationsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pushModal } = useModal();
  const options = { merge: true };
  const deps = [useSelector((state: ICoreModuleState) => state.badges.updatedAt)];
  const { items, loading, hasMore, loadNext } = usePagination<INotification>('notifications.list', options, deps);

  const notifications = items.map((i) => {
    const { href } = i.payload ?? {};
    if (href && href.indexOf('/r/invites') > -1) {
      // eslint-disable-next-line no-param-reassign
      i.payload = undefined;
      // eslint-disable-next-line no-param-reassign
      i.onClick = () => pushModal(NotSupported, {});
    }

    return i;
  });

  const setAsRead = async (_id: string) => {
    await MeteorCall('notifications.read', _id);
    dispatch(updatedBadgesAt(new Date().getTime()));
  };

  const onNotificationClick = (notification: INotification) => () => {
    const { payload, onClick, _id } = notification;
    if (payload) {
      navigate(NybyUri.relative(payload?.href ?? ''));
    } else if (onClick) {
      onClick();
    }
    setAsRead(_id);
  };

  return {
    loading,
    notifications,
    hasMore,
    loadNext,
    setAsRead,
    onNotificationClick,
  };
}
