import { FC, forwardRef, memo, ReactNode, useEffect, useRef } from 'react';

import Button, { BackButton, CloseButton } from 'Interface/Button';

import { clearModalStack } from 'Modules/core/actions';
import { i18n } from 'Configs/i18n';
import { useDispatch } from 'react-redux';
import * as S from './Modal.styles';
import useModalInternal from './useModalInternal';

type IModalProps = {
  children: JSX.Element[] | JSX.Element | Element | null | false;
  tabIndex?: number;
  loading?: boolean;
};

const Modal: FC<IModalProps> = (props) => {
  const { children, tabIndex = 0, loading = false } = props;
  const modalRef = useRef<any>();
  const triggerRef = useRef<any>();

  useEffect(() => {
    if (modalRef.current) {
      // triggerRef.current = document.querySelector(':focus');
      // modalRef.current.focus();
    }
    return () => {
      if (triggerRef.current) {
        // triggerRef.current.focus();
      }
    };
  }, [modalRef]);

  return (
    <S.ModalRoot role="dialog" ref={modalRef} tabIndex={tabIndex}>
      {loading ? <S.ModalLoading light inline /> : null}
      {children}
    </S.ModalRoot>
  );
};

type IHeaderProps = {
  onBack?: boolean | (() => void) | undefined;
  onClose?: () => void | undefined;
  noShadow?: boolean | undefined;
  title?: ReactNode;
  subTitle?: string;
  noBack?: boolean;
  noClose?: boolean;
};

const Header: FC<IHeaderProps> = (props) => {
  const { children, onBack, onClose, noShadow = false, noClose = false, title, subTitle, noBack, ...restProps } = props;
  const { onBackCallback, onClearModalStack } = useModalInternal();
  const handleOnBack = onBackCallback(onBack);

  return (
    <S.HeaderRoot noShadow={noShadow} {...restProps}>
      {handleOnBack ? (
        <BackButton tabIndex={0} onClick={handleOnBack} />
      ) : (
        <BackButton style={{ visibility: 'hidden', ...(noBack ? { display: 'none' } : {}) }} />
      )}
      {children || (
        <S.Center>
          <h2>{title}</h2>
          <S.Small>{subTitle}</S.Small>
        </S.Center>
      )}
      {!noClose && <CloseButton tabIndex={0} onClick={onClose || (() => onClearModalStack())} />}
    </S.HeaderRoot>
  );
};

export const Cancel: FC<{ close?: boolean }> = ({ close = true }) => {
  const dispatch = useDispatch();
  const onClick = close ? () => dispatch(clearModalStack()) : () => null;
  return (
    <Button type="secondary-alt" onClick={onClick}>
      {i18n.__('global.cancel')}
    </Button>
  );
};

const Content = forwardRef<HTMLDivElement>((props, ref) => <S.ContentContainer ref={ref} {...props} />);

export default memo(Modal);
export { Header, Content };
