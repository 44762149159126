import { useEffect } from 'react';

function withinParent(container: HTMLElement, event: MouseEvent | TouchEvent) {
  let node: any = event.target;
  while (node) {
    if (container.contains(node)) {
      return true;
    }
    if (node.parentNode) {
      node = node.parentNode;
    } else {
      break;
    }
  }
  return false;
}

type Node = HTMLElement | null;
export const useOnClickOutside = (container: Node, target: Node, visible: boolean, handler: () => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!visible || !container || withinParent(container, event) || (target && withinParent(target, event))) {
        return;
      }
      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [container, handler]);
};
