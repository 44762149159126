import LanguageDetector from 'i18next-browser-languagedetector';
import * as en from 'Languages/en.i18n.json';
import * as nb from 'Languages/nb.i18n.json';
import * as sv from 'Languages/sv.i18n.json';
import * as de from 'Languages/de.i18n.json';
import * as da from 'Languages/da.i18n.json';
import MessageFormat from 'messageformat';
import moment from 'moment';
import React from 'react';
import { createStore } from 'redux';
import { mapValues } from 'lodash';

export const availableLocales = ['en', 'nb', 'sv', 'de', 'da'];
export const defaultLocale = 'en';

const I18N_STORE = createStore(
  function (state, event) {
    return { locale: event.locale };
  },
  { locale: null }
);

function getMomentSettingsForLocale(locale) {
  if (locale === 'en') {
    return { language: 'en', settings: {} };
  }
  if (locale === 'sv') {
    return { language: 'sv', settings: {} };
  }
  if (locale === 'de') {
    return { language: 'de', settings: {} };
  }
  if (locale === 'da') {
    return { language: 'da', settings: {} };
  }
  return { language: 'nb', settings: {} };
}

/**
 * Store locale settings.
 */
class I18NSettings {
  lang = new LanguageDetector({
    languageUtils: {
      formatLanguageCode(lang) {
        return lang.toLowerCase().substring(0, 2);
      },
      isWhitelisted(lang) {
        return availableLocales.indexOf(lang) !== -1;
      },
    },
  });

  constructor() {
    I18NSettings.#updateLocale(this.lang.detect());
  }

  static #updateLocale(locale) {
    const { language, settings } = getMomentSettingsForLocale((locale && locale.substring(0, 2)) ?? 'en');
    moment.updateLocale(language, settings);
    I18N_STORE.dispatch({ type: 'locale-change', locale });
  }

  /**
   * Change and remember locale
   * @param locale
   */
  changeLocaleInStore(locale) {
    const current = I18N_STORE.getState().locale;
    // console.log(`Changing locale from ${current} => ${locale}`);
    if (current === locale) {
      return;
    }
    I18NSettings.#updateLocale(locale);
    this.lang.cacheUserLanguage(locale);
  }

  getDefaultLocale() {
    return defaultLocale;
  }

  getCurrentLocale() {
    return I18N_STORE.getState().locale || this.getDefaultLocale();
  }
}

function get(object, key, defaultValue) {
  if (typeof object !== 'object' || object === null) {
    return defaultValue;
  }

  if (typeof key !== 'string') {
    throw new Error('Key must be string.');
  }

  const keys = key.split('.');
  const last = keys.pop();
  while ((key = keys.shift())) {
    if (object[key]) {
      object = object[key];
    }
    if (object.default && object.default[key]) {
      object = object.default[key];
    }
    if (typeof object !== 'object' || object === null) {
      return defaultValue;
    }
  }
  return object && object[last] !== undefined ? object[last] : defaultValue;
}

class Localization {
  constructor(translations, formatter) {
    this._translations = translations;
    this._formatter = formatter;
    this._settings = new I18NSettings();
  }

  __() {
    const args = [].slice.call(arguments);
    const keysArr = args.filter((prop) => typeof prop === 'string' && prop);

    const key = keysArr.join('.');
    let params = {};
    if (typeof args[args.length - 1] === 'object') {
      params = args[args.length - 1];
    }

    const currentLang = params._locale || this._settings.getCurrentLocale();
    let token = currentLang + '.' + key;
    let string = get(this._translations, token);
    if (!string) {
      token = currentLang.replace(/-.+$/, '') + '.' + key;
      string = get(this._translations, token);

      if (!string) {
        token = this._settings.getDefaultLocale() + '.' + key;
        string = get(this._translations, token);

        if (!string) {
          token = this._settings.getDefaultLocale().replace(/-.+$/, '') + '.' + key;
          string = get(this._translations, token, key);
        }
      }
    }

    if (!string) {
      console.error(`Unable to find translation: ${token}`);
      return '';
    }

    return this.#tr(string, params);
  }

  changeLocale(locale) {
    this._settings.changeLocaleInStore(locale);
  }

  #tr(str, params) {
    if (!str) {
      return '';
    }

    let locale = (params._locale || this._settings.getCurrentLocale()).substring(0, 2);
    if (availableLocales.indexOf(locale) === -1) {
      locale = this._settings.getDefaultLocale();
    }

    return this._formatter.compile(str, locale)(params);
  }

  getDefaultLocale() {
    return this._settings.getDefaultLocale();
  }

  getCurrentLocale() {
    return this._settings.getCurrentLocale();
  }
}

const MF = new MessageFormat(availableLocales);
const i18n = new Localization({ en, nb, sv, de, da }, MF);
i18n.translate = i18n.__;

function findPlaceholderMatches(text, keys) {
  const matches = [];
  for (const k of keys) {
    const s = text.indexOf(k);
    if (s >= 0) {
      matches.push({ startsAt: s, endsAt: s + k.length, key: k });
    }
  }
  return matches;
}

function replaceWithComponents(text, components) {
  const children = [];
  let start = 0;
  for (const match of findPlaceholderMatches(text, Object.keys(components))) {
    children.push(text.substring(start, match.startsAt));
    children.push(components[match.key]);
    start = match.endsAt;
  }
  if (text.length > start) {
    children.push(text.substring(start));
  }
  return children.length > 0 ? children : text;
}

function placeholderId() {
  const id = Math.random().toString(36).substr(2, 9);
  return `<T${id}/>`;
}

const T = (props) => {
  const { children, ...rest } = props;
  const tagType = 'span';
  const components = {};
  const params = mapValues(rest ?? {}, (p) => {
    if (React.isValidElement(p)) {
      // replace react components with a string placeholder id
      const id = placeholderId();
      components[id] = React.cloneElement(p, { key: p.props.key ?? id });
      return id;
    }
    return p;
  });
  const items = React.Children.map(children, (item, index) => {
    if (typeof item === 'string' || typeof item === 'number') {
      // build children fragments where in the translated text
      // replace the string placeholder id with the corresponding react component
      const translated = replaceWithComponents(i18n.translate(item, params), components);
      if (translated[0].indexOf('<a') > -1) {
        const link = translated[0];
        const href = link.match(/href="([^"]*)/)[1];
        const target = link.match(/target="([^"]*)/)[1];
        const text = link.match(/<a [^>]+>([^<]+)<\/a>/)[1];
        return React.createElement('a', { key: '_' + index, href, target }, text);
      }
      return React.createElement(tagType, { key: '_' + index }, translated);
    }
    return item;
  });
  return <>{items}</>;
};

export { i18n, T };
