/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import Meteor, { IPaginationResult, useMethod } from 'API/Meteor';
import { uniqBy } from 'lodash';

type Options<T> = {
  params?: any;
  pageSize?: number;
  page?: number;
  search?: boolean;
  merge?: boolean; // merge with existing results
  onMerge?: (existing: T[], newItems: T[]) => T[];
};

function calculateDeps(method: string, args: Record<any, any>, deps: any[]) {
  return [method, Meteor.userId(), JSON.stringify(Object.values(args)), ...deps];
}

// eslint-disable-next-line default-param-last
export function usePagination<T>(method: string, options: Options<T> = {}, deps?: any[]) {
  const [page, setPage] = useState(0);
  const [loadFirstPage, setLoadFirstPage] = useState(false);
  const clear = useRef(false);
  const [keyword, setKeyword] = useState('');
  const [items, setItems] = useState<T[]>([]);
  const { pageSize = 20, params = {}, search = false, merge = false } = options;
  const args = { pageSize, page: loadFirstPage ? 0 : page, ...(search ? { keyword } : {}), ...params };
  const dependencies = calculateDeps(method, args, deps ?? []);
  const { result, loading } = useMethod<IPaginationResult<T>>(method, args, dependencies);
  const total = useRef(0);
  const newItems = result?.items || [];
  const hasMore = items.length < total.current;
  const data = result?.data;

  const loadNext = () => {
    if (result?.page === page) {
      setLoadFirstPage(false);
      setPage(page + 1);
    }
  };

  const doSearch = (s: string) => {
    clear.current = true;
    setPage(0);
    setKeyword(s);
  };

  const changePage = (p: number) => {
    clear.current = true;
    setPage(p);
  };

  useEffect(() => {
    if (result?.total || result?.total === 0) {
      total.current = result.total;
    }
  }, [result?.total]);

  useEffect(() => {
    if (!loading) {
      if (clear.current || !merge) {
        setItems(newItems);
        clear.current = false;
      } else if (options.onMerge) {
        setItems(options.onMerge(items, newItems));
      } else {
        setItems(uniqBy([...items, ...newItems], '_id'));
      }
    }
  }, [loading, result?.page, result?.total]);

  return {
    items,
    data,
    loading,
    page,
    total: total.current,
    hasMore,
    loadNext,
    search: doSearch,
    setPage: changePage,
    setLoadFirstPage,
  };
}
