import { memo } from 'react';
import { ThemeProvider } from 'styled-components';

import { breakpoints } from 'Styles/theme';

import Loading from 'Interface/Loading';

import ErrorBoundary from './ErrorBoundary';
import Content from './Content';
import ModalManager from './ModalManager';
import NotificationBar from './NotificationBar';
import APISubscriptions from './APISubscriptions';
import NoAuth from './NoAuth';
import NonAdmin from './NonAdmin';

import useCore from './useCore';

const Core = () => {
  const { loading, user, isAdmin } = useCore();

  const theme = {
    flexboxgrid: {
      gridSize: 12,
      gutterWidth: 0,
      outerMargin: 0,
      mediaQuery: 'only screen',
      container: {
        sm: breakpoints.sm + 1,
        md: breakpoints.md + 1,
        lg: breakpoints.lg + 1,
      },
      breakpoints: {
        xs: breakpoints.xs,
        sm: breakpoints.sm,
        md: breakpoints.md,
        lg: breakpoints.lg,
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      {/* isStagingEnv() && <Ribbon /> */}

      <ErrorBoundary>
        {!loading && !user && <NoAuth />}
        {!loading && user && isAdmin && <Content />}
        {!loading && user && !isAdmin && <NonAdmin />}
      </ErrorBoundary>

      <ModalManager />
      <NotificationBar />
      {user && <APISubscriptions />}
      {loading && <Loading light transparent />}
    </ThemeProvider>
  );
};

export default memo(Core);
