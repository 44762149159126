import CrossIcon from 'Assets/svg/icons/cross-xsmall.svg';

import { IFilterItem } from '../FilterMenu.types';

import * as S from './FilterTag.styles';

type IFilterTagProps = {
  onCrossClick: () => void;
} & IFilterItem;

const FilterTag = (props: IFilterTagProps) => {
  const { label, onCrossClick } = props;

  return (
    <S.Root>
      <span>{label}</span>
      <CrossIcon width={10} onClick={onCrossClick} />
    </S.Root>
  );
};

export { FilterTag };
