import { T } from 'Configs/i18n';

import Modal, { Header } from 'Interface/Modal';

import FriendsIllustrations from 'Assets/svg/illustrations/friends_cat.svg';

import * as S from './NotSupported.styles';
import useNotSupported from './useNotSupported';

const NonSupported = () => {
  const { onClose } = useNotSupported();

  return (
    <Modal>
      <Header onClose={onClose} noShadow />
      <S.Root>
        <FriendsIllustrations width={285} />
        <S.Header>
          <T>not_supported.title</T>
        </S.Header>
        <S.Content>
          <T>not_supported.content</T>
        </S.Content>
        <S.Ok onClick={onClose}>
          <T>not_supported.button</T>
        </S.Ok>
      </S.Root>
    </Modal>
  );
};

export default NonSupported;
