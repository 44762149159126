import { useModal } from 'Hooks/useModal';

export default function useNotSupported() {
  const { clearStack } = useModal();

  const onClose = () => {
    clearStack();
  };

  return {
    onClose,
  };
}
