import { AnyAction } from 'redux';
import * as Actions from '../actions/actionTypes';

import { IModalState } from '../types';

export const initialState: IModalState = {
  visible: false,
  stack: [],
  stackIndex: 0,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Actions.PUSH_MODAL:
      return {
        visible: true,
        stack: [...state.stack, { dialog: action.dialog, props: action.props, focus: action.focus }],
        stackIndex: (state.stackIndex || 0) + 1,
      };

    case Actions.POP_MODAL: {
      const stack = [...state.stack.slice(0, state.stack.length - 1)];
      return {
        visible: !!stack.length,
        stack,
        stackIndex: (state.stackIndex || 0) - 1,
      };
    }

    case Actions.CLEAR_MODAL_STACK:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
