import { useState } from 'react';
import { IExpanded } from './FilterMenu.types';

export default function useFilterMenu() {
  const [expanded, setExpanded] = useState<IExpanded[]>([
    { domain: 'groups', expanded: false },
    { domain: 'teams', expanded: false },
    { domain: 'permissions', expanded: false },
  ]);
  const toggleExpand = (domain: string) => {
    const newExpanded = expanded.map((item) => {
      if (item.domain === domain) {
        return { ...item, expanded: !item.expanded };
      }
      return item;
    });
    setExpanded(newExpanded);
  };
  return {
    expanded,
    toggleExpand,
  };
}
