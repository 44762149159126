import Avatar from 'Interface/Avatar';
import { ShortName } from 'Interface/DisplayName';
import DropDownMenu from 'Interface/DropDownMenu';

import * as S from './UserProfile.styles';
import useUserProfile from './useUserProfile';

export default function UserProfile() {
  const { user, actions } = useUserProfile();

  return (
    <DropDownMenu data={actions} placement="bottom-end">
      <S.User>
        <Avatar source={user} size={30} />
        <S.Name>
          <ShortName source={user} />
        </S.Name>
      </S.User>
    </DropDownMenu>
  );
}
