import { PayloadAction } from '@reduxjs/toolkit';
import { createReduxState } from 'Libs/helpers';
import { IAuthor } from 'API/types';

type IMessagesState = {
  threadId?: string;
  threadIds?: string[];
  previewThreadId?: string;
  messagesPage: number;
  newChat: boolean;
  keyword: string;
  participants: IAuthor[];
  showPreview: boolean;
  deletedThreadIds: string[];
};

const initialState: IMessagesState = {
  threadId: undefined,
  threadIds: [],
  previewThreadId: undefined,
  messagesPage: 1,
  newChat: false,
  keyword: '',
  participants: [],
  showPreview: true,
  deletedThreadIds: [],
};

const {
  reducers,
  actions,
  useSelector: useMessagesSelector,
} = createReduxState({
  name: 'messages',
  initialState,
  reducers: {
    selectThreadId(state, { payload: threadId }: PayloadAction<string | undefined>) {
      state.threadId = threadId;
      state.previewThreadId = undefined;
      state.newChat = false;
    },
    selectPreview(state, { payload: id }: PayloadAction<string | undefined>) {
      state.previewThreadId = id;
    },
    setShowPreview(state, { payload: show }: PayloadAction<boolean>) {
      state.showPreview = show;
    },
    setNewChat(state, { payload: newChat }: PayloadAction<boolean>) {
      state.newChat = newChat;
      state.threadId = undefined;
      state.previewThreadId = undefined;
      state.participants = [];
      state.keyword = '';
    },
    setKeyword(state, { payload: keyword }: PayloadAction<string>) {
      state.keyword = keyword;
    },
    addParticipant(state, { payload: participant }: PayloadAction<IAuthor>) {
      state.participants = [...state.participants, participant];
    },
    setParticipants(state, { payload: participants }: PayloadAction<IAuthor[]>) {
      state.participants = participants;
    },
    removeParticipant(state, { payload: participantId }: PayloadAction<string>) {
      state.participants = state.participants.filter((p) => p._id !== participantId);
    },
    setThreadIds(state, { payload: threadIds }: PayloadAction<string[]>) {
      state.threadIds = threadIds;
    },
    addDeletedThreadId(state, { payload: deletedThreadId }: PayloadAction<string>) {
      state.deletedThreadIds = [...state.deletedThreadIds, deletedThreadId];
    },
  },
});

export { reducers, actions, useMessagesSelector };
