import { Selector, useSelector as useReduxSelector } from 'react-redux';
import modal from './modal';
import notificationBar from './notificationBar';
import badges from './badges';
import buckets from './buckets';
import { ICoreModuleState } from '../types';

export * from '../types';
export default { modal, notificationBar, badges, buckets };

export function useSelector<T>(selector: Selector<ICoreModuleState, T>) {
  return useReduxSelector<ICoreModuleState, T>(selector);
}
