import { IAvatarSource } from 'Interface/Avatar';

export function displayName(source?: IAvatarSource, max = -1) {
  const { name, firstname, lastname, title } = source ?? {};
  const names = [];
  if (title && typeof title === 'string') {
    names.push(title);
  } else if (name && typeof name === 'string') {
    names.push(name);
  } else {
    if (firstname && typeof firstname === 'string') {
      names.push(firstname);
    }
    if (lastname && typeof lastname === 'string') {
      names.push(lastname);
    }
  }
  if (max > 0) {
    return names.splice(0, max).join(' ');
  }
  return names.join(' ');
}

export default function DisplayName({ source }: { source?: IAvatarSource }) {
  return <>{displayName(source)}</>;
}

export function ShortName({ source }: { source?: IAvatarSource }) {
  return <>{displayName(source, 1)}</>;
}
