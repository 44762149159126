import { FC } from 'react';
import { clearModalStack, popModal, pushModal } from 'Modules/core/actions';
import { useDispatch } from 'react-redux';

type IModalMatchingProps = <A>(nextStepModal: FC<A>, props: A, focus?: boolean) => void;

export function useModal() {
  const dispatch = useDispatch();

  // Push a modal with props, ensure that the modal props and given props match!
  const pushModalMatchingProps: IModalMatchingProps = (step, props, focus = true) =>
    dispatch(pushModal(step, props, focus));

  return {
    pushModal: pushModalMatchingProps,
    popModal: () => dispatch(popModal()),
    clearStack: () => dispatch(clearModalStack()),
  };
}
