/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-shadow */
import styled, { css } from 'styled-components';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { breakpoints } from 'Styles/theme';
import { theme } from './theme';

export type IStyleProps = {
  tag?: string;
  $fontFamily?: string;
  $fontStyle?: string;
  $fontWeight?: string | number;
  $lineHeight?: (string | boolean)[] | string;
  $fontSize?: (string | boolean)[] | string;
  $textTransform?: string;
};

function inject(prop: string, value: any | any[], index = 0) {
  if (Array.isArray(value) && value[index]) {
    return css`
      ${prop}: ${value[index]};
    `;
  }
  if (!Array.isArray(value) && value && index === 0) {
    return css`
      ${prop}: ${value};
    `;
  }
}

// Dynamically create typography styles based on prop values
const Style = styled.div<IStyleProps>`
  ${(props) => inject('font-family', props.$fontFamily)}
  ${(props) => inject('font-style', props.$fontStyle)}
  ${(props) => inject('font-weight', props.$fontWeight)}
  ${(props) => inject('text-transform', props.$textTransform)}

  ${(props) => inject('font-size', props.$fontSize, 0)}
  ${(props) => inject('line-height', props.$lineHeight, 0)}
  
  @media only screen and (max-width: ${breakpoints.lg}em) {
    ${(props) => inject('font-size', props.$fontSize, 1)}
    ${(props) => inject('line-height', props.$lineHeight, 1)}
  }
  
  @media only screen and (max-width: ${breakpoints.md}em) {
    ${(props) => inject('font-size', props.$fontSize, 2)}
    ${(props) => inject('line-height', props.$lineHeight, 2)}
  }

  @media only screen and (max-width: ${breakpoints.sm}em) {
    ${(props) => inject('font-size', props.$fontSize, 3)}
    ${(props) => inject('line-height', props.$lineHeight, 3)}
  }
}`;

function TextComponent(style: IStyleProps, props: PropsWithChildren<any>, ref?: any) {
  const { tag, children } = props;
  const { tag: defaultTag, ...settings } = style;
  return (
    <Style ref={ref} as={tag ?? defaultTag} {...settings} {...props}>
      {children}
    </Style>
  );
}

type ITextProps = PropsWithChildren<HTMLAttributes<HTMLElement> & { tag?: string }>;

// names from https://www.bbc.co.uk/gel/guidelines/typography
// and https://en.wikipedia.org/wiki/Traditional_point-size_names

export const B24 = (tag?: keyof JSX.IntrinsicElements) => {
  function DoublePica(props: ITextProps, ref?: any) {
    return TextComponent(theme.b24, { tag, ...props }, ref);
  }
  return forwardRef(DoublePica);
};

export const B20 = (tag?: keyof JSX.IntrinsicElements) => {
  function GreatPrimer(props: ITextProps, ref?: any) {
    return TextComponent(theme.b20, { tag, ...props }, ref);
  }
  return forwardRef(GreatPrimer);
};

export const B16 = (tag?: keyof JSX.IntrinsicElements) => {
  function Pica(props: ITextProps, ref?: any) {
    return TextComponent(theme.b16, { tag, ...props }, ref);
  }
  return forwardRef(Pica);
};

export const B14 = (tag?: keyof JSX.IntrinsicElements) => {
  function LongPrimer(props: ITextProps, ref?: any) {
    return TextComponent(theme.b14, { tag, ...props }, ref);
  }
  return forwardRef(LongPrimer);
};

export const B12 = (tag?: keyof JSX.IntrinsicElements) => {
  function Bourgeois(props: ITextProps, ref?: any) {
    return TextComponent(theme.b12, { tag, ...props }, ref);
  }
  return forwardRef(Bourgeois);
};

export const R16 = (tag?: keyof JSX.IntrinsicElements) => {
  function BodyCopy(props: ITextProps, ref?: any) {
    return TextComponent(theme.r16, { tag, ...props }, ref);
  }
  return forwardRef(BodyCopy);
};

export const R14 = (tag?: keyof JSX.IntrinsicElements) => {
  function English(props: ITextProps, ref?: any) {
    return TextComponent(theme.r14, { tag, ...props }, ref);
  }
  return forwardRef(English);
};

export const R13 = (tag?: keyof JSX.IntrinsicElements) => {
  function Brevier(props: ITextProps, ref?: any) {
    return TextComponent(theme.r13, { tag, ...props }, ref);
  }
  return forwardRef(Brevier);
};

export const R12 = (tag?: keyof JSX.IntrinsicElements) => {
  function Minion(props: ITextProps, ref?: any) {
    return TextComponent(theme.r12, { tag, ...props }, ref);
  }
  return forwardRef(Minion);
};

export const AllSizes = [B24(), B20(), B16(), B14(), B12(), R16(), R14(), R13(), R12()];
