import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import Loading from 'Interface/Loading';

import { PRELOADING_DELAY } from 'Constants/app';

import { reducers as messages } from 'Modules/messages/reducers';
import { reducers } from './reducers';

const Units = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light />,
});

export default () => (
  <DynamicModuleLoader modules={[reducers(), messages()]}>
    <Units />
  </DynamicModuleLoader>
);
