import { FC, useEffect, memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Col } from 'react-styled-flexboxgrid';
import { useDispatch } from 'react-redux';

import { i18n } from 'Configs/i18n';
import { useSelector } from 'Modules/core/reducers';
import { hideNotificationBar, removeNotificationBar } from 'Modules/core/actions';

import { INotificationState } from '../../types';
import * as S from './NotificationBar.styles';

export const NotificationBarMessage: FC<INotificationState['messages'][0]> = memo(
  ({ visible = true, type = 'info', message, timeout = 5000, label = i18n.__('global.ok'), id }) => {
    const dispatch = useDispatch();
    const timeoutRef = useRef<number>(0);

    const closeBar = () => {
      dispatch(hideNotificationBar([id]));
    };

    const onAnimationEnd = () => {
      if (!visible) dispatch(removeNotificationBar([id]));
    };

    const onMouseOver = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };

    const onMouseOut = () => {
      timeoutRef.current = window.setTimeout(closeBar, timeout);
    };

    useEffect(() => {
      if (visible) {
        timeoutRef.current = window.setTimeout(closeBar, timeout);
      }
    }, [visible]);

    return (
      <S.Bar
        type={type}
        visible={visible}
        onAnimationEnd={onAnimationEnd}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={() => closeBar()}
      >
        <S.Message>{message}</S.Message>
        <S.Button onClick={() => closeBar()}>{label}</S.Button>
      </S.Bar>
    );
  }
);

export function getNotificationContainer() {
  const container = document.getElementById('notifications') as HTMLDivElement;
  if (container) return container;

  const newContainer = document.createElement('div');
  newContainer.id = 'notifications';
  document.body.appendChild(newContainer);

  return newContainer;
}

const NotificationBar: FC<Partial<INotificationState>> = () => {
  const messages = useSelector((state) => state.notificationBar.messages);

  return createPortal(
    <S.Root center="xs">
      <Col xs={false} sm={false} md={1} lg={2} />
      <Col xs={11} sm={10} md={10} lg={8}>
        {messages.map((message) => (
          <NotificationBarMessage key={message.id} {...message} />
        ))}
      </Col>
      <Col xs={false} sm={false} md={1} lg={2} />
    </S.Root>,
    getNotificationContainer()
  );
};

export default NotificationBar;
