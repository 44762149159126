import InfiniteScroll from 'react-infinite-scroll-component';

import { i18n } from 'Configs/i18n';
import { dateFromNow } from 'Libs/helpers';

import { INotification } from 'API/types/INotification';

import { closeDropDown } from 'Interface/DropDown';
import Avatar from 'Interface/Avatar/Avatar';
import Loading from 'Interface/Loading';
import DisplayName from 'Interface/DisplayName';

import CrossIcon from 'Assets/svg/icons/cross.svg';

import * as S from './NotificationsList.styles';
import useNotificationsList from './useNotificationsList';

export default function NotificationsList({ scrollableTarget }: { scrollableTarget?: string }) {
  const { notifications, onNotificationClick, hasMore, loadNext, loading } = useNotificationsList();

  const renderHeader = () => (
    <S.Header>
      {i18n.__('global.notifications')}
      <S.HeaderCross onClick={closeDropDown()}>
        <CrossIcon width={18} />
      </S.HeaderCross>
    </S.Header>
  );

  const renderNotification = (item: INotification) => {
    const { body, createdAt, status } = item;
    const onClick = closeDropDown(onNotificationClick(item));

    return (
      <S.Item key={item._id} onClick={onClick} $status={status}>
        <S.Notification>
          <Avatar size={40} source={item.teamObj ?? item.avatar ?? item} />
          <S.Content>
            <S.Name>
              <DisplayName source={item.teamObj ?? item.avatar} />
            </S.Name>
            <S.Body>{body?.body}</S.Body>
            <S.Date>{dateFromNow(createdAt)}</S.Date>
          </S.Content>
        </S.Notification>
      </S.Item>
    );
  };

  const renderNotifications = (items: INotification[]) => items.map((item) => renderNotification(item));

  const renderNotificationsList = () => (
    <S.Scroll id="scrollableWrapper">
      <InfiniteScroll
        dataLength={notifications.length}
        hasMore={hasMore}
        next={loadNext}
        loader={<span />}
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        scrollableTarget={scrollableTarget ?? 'scrollableWrapper'}
      >
        {renderNotifications(notifications)}
      </InfiniteScroll>
    </S.Scroll>
  );

  const renderLoading = () => (
    <S.Loading>
      <Loading inline light />
    </S.Loading>
  );

  return (
    <>
      {renderHeader()}
      {loading && renderLoading()}
      {!loading && renderNotificationsList()}
    </>
  );
}
