import styled from 'styled-components';
import { B14, B16 } from 'Styles/Typography';
import { colors, space } from 'Styles/theme';

export const Root = styled.div`
  margin-bottom: ${space.px24};
`;

export const Title = styled(B16())`
  color: ${colors.cobalt10};
  margin-bottom: ${space.px8};
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterItem = styled(B14())<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  height: 32px;
  border: 1px solid ${colors.grey1};
  cursor: pointer;
  padding: 0 ${space.px12};
  margin: 0 ${space.px8} ${space.px8} 0;
  transition: all 0.3s ease;
  background: ${({ selected }) => (selected ? colors.blue10 : '')};
  color: ${({ selected }) => (selected ? 'white' : '')};

  &:hover {
    background: ${colors.blue1};
    border-color: ${colors.blue1};
    color: ${colors.blue10};
  }
`;

export const ViewAll = styled(B14())`
  color: ${colors.blue10};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
