import { MouseEvent, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clearModalStack, popModal } from 'Modules/core/actions';

import { MODAL_CONTAINER_ID } from 'Constants/app';
import { ICoreModuleState } from 'Modules/core/types';

type IEscape = (event: MouseEvent<HTMLDivElement> | KeyboardEvent, force?: boolean) => void;

export default function useModalManager() {
  const dispatch = useDispatch();
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const { visible, stack, stackIndex } = useSelector((state: ICoreModuleState) => state.modal);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const closeModal: IEscape = (event, force = false) => {
    if (event.target === overlayRef.current || force) {
      dispatch(clearModalStack());
    }
  };

  const pop: IEscape = (event, force = false) => {
    if (event.target === overlayRef.current || force) {
      dispatch(popModal());
    }
  };

  const getModalContainer = () => {
    const container = document.getElementById('modal') as HTMLDivElement;
    if (container) return container;

    const newContainer = document.createElement('div');
    newContainer.id = MODAL_CONTAINER_ID;
    document.body.appendChild(newContainer);

    return newContainer;
  };

  useEffect(() => {
    dispatch(clearModalStack());
  }, [pathname]);

  useLayoutEffect(() => {
    const html = document.querySelector('html');
    containerRef.current = getModalContainer();

    if (visible) {
      const { scrollTop = 0 } = html || {};
      html?.classList.add('overflow');
      document.body.scrollTop = scrollTop;
      if (html) html.scrollTop = 0; // safari hack
    } else {
      const { scrollTop } = document.body;
      html?.classList.remove('overflow');
      if (html) html.scrollTop = scrollTop;
    }
  }, [visible]);

  return {
    stack,
    stackIndex,
    closeModal,
    pop,
    overlayRef,
    visible,
    containerRef,
  };
}
