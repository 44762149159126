export default {
  messagesHome() {
    return '/messages';
  },

  viewMessage(threadId: string) {
    return `/messages/${threadId}`;
  },

  newMessage(userId?: string) {
    return userId ? `/messages/new/${userId}` : `/messages/new`;
  },
};
