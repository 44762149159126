import { Navigate, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import Home from 'Modules/home';
import Groups from 'Modules/groups';
import Messages from 'Modules/messages';
import Requests from 'Modules/requests';
import Posts from 'Modules/posts';
import Settings from 'Modules/settings';
import Invites from 'Modules/invites';
import Units from 'Modules/units';
import User from 'Modules/user';
import Download from 'Modules/download';

import { getTitle } from 'Libs/helpers';

import * as S from './Content.styles';

const Content = () => {
  const renderDesktopRoutes = () => (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/home" element={<Home />} />
      <Route path="/groups/*" element={<Groups />} />
      <Route path="/messages/*" element={<Messages />} />
      <Route path="/requests/*" element={<Requests />} />
      <Route path="/posts/*" element={<Posts />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/invites/*" element={<Invites />} />
      <Route path="/units/*" element={<Units />} />
      <Route path="/user/*" element={<User />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );

  const renderMobileRoutes = () => (
    <Routes>
      <Route path="/" element={<Navigate to="/download" replace />} />
      <Route path="/download" element={<Download />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );

  return (
    <>
      {!isMobile && <S.TopBar />}
      <S.Container>
        <Helmet>
          <title>{getTitle()}</title>
        </Helmet>
        {!isMobile && renderDesktopRoutes()}
        {isMobile && renderMobileRoutes()}
      </S.Container>
    </>
  );
};

export default Content;
