/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import Meteor, { IUsePublication } from 'meteor-react-js';
import { IMeteorError } from 'API/types';
import { clearCache } from 'Hooks/useCache';

export const MeteorCall = async <T>(name: string, ...params: any[]) => {
  const promise = new Promise<T & IMeteorError>((resolve, _) => {
    Meteor.call(name, ...params, (err: IMeteorError, res: any) => {
      if (err) {
        resolve(err as any);
      } else {
        resolve(res);
      }
    });
  });
  // eslint-disable-next-line no-console
  promise.catch((e) => console.log(e));
  return promise;
};

export const shouldLogMeteor = () => Boolean(process.env.LOG_METEOR);

export type IUserSession = {
  id: string;
  token: string;
  tokenExpires: Date;
  userId?: string;
};

export type IPaginationResult<T> = {
  total: number;
  page: number;
  pageSize: number;
  limit: number;
  data?: Record<string, any>;
  items: T[];
};

export const saveUserToken = (data: IUserSession) => {
  const { id, token, tokenExpires } = data;
  localStorage.setItem('Meteor.userId', id);
  localStorage.setItem('Meteor.loginToken', token);
  localStorage.setItem('Meteor.loginTokenExpires', tokenExpires.toISOString());
  Cookies.set('__session', Meteor.Random.id(), { expires: tokenExpires, sameSite: 'Lax' });
  Meteor._handleLoginCallback(null, data);
};

export const deleteUserToken = () => {
  clearCache();
  localStorage.removeItem('Meteor.userId');
  localStorage.removeItem('Meteor.loginToken');
  localStorage.removeItem('Meteor.loginTokenExpires');
  Cookies.remove('__session', { sameSite: 'Lax' });
  // Meteor.ddp.emit('logout');
};

/**
 * Used by API doc extractor to find where meteor methods are used without
 * usual method calls like `useMethod()` or `Meteor.call()`, etc.
 */
export function MeteorMethod(name: string) {
  return name;
}

/**
 * Used by API doc extractor to find where meteor publication are used without
 * usual subscription calls like `usePublication()` or `Meteor.subscribe()`, etc.
 */
export function MeteorPublication(name: string) {
  return name;
}

// re-wrap usePublication so we can mock Meteor.usePublication
function usePublication<T>(publication: IUsePublication, dependencies?: any[]) {
  return Meteor.usePublication<T>(publication, dependencies);
}

const { Accounts, Mongo, useMethod, useTracker } = Meteor;
export { Accounts, Mongo, usePublication, useMethod, useTracker };
export default Meteor;
