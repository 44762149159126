import styled from 'styled-components';

export const Root = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;

  & span {
    font-size: 14px;
  }
`;
