import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { colors, fonts } from 'Styles/theme';
import {
  ButtonShapes,
  ButtonSizes,
  IButtonBase,
  IButtonLink,
  IButtonProps,
  IButtonTheme,
  IButtonTypeColors,
} from './Button.types';

export const buttonTheme: IButtonTheme = {
  link: {
    active: 'transparent',
    background: 'transparent',
    click: 'transparent',
    color: colors.grey6,
  },
  action: {
    active: colors.blue11,
    background: colors.blue10,
    click: colors.blue10,
    color: '#fff',
  },
  positive: {
    active: colors.green5,
    background: colors.green4,
    click: colors.green4,
    color: '#fff',
  },
  negative: {
    active: colors.red5,
    background: colors.red4,
    click: colors.red4,
    color: '#fff',
  },
  ghost: {
    active: `#00000010`,
    background: 'transparent',
    click: `#00000010`,
    color: colors.grey6,
  },
  secondary: {
    active: colors.beige7,
    background: colors.beige6,
    click: colors.beige6,
    color: colors.grey6,
  },
  'secondary-alt': {
    active: colors.grey2,
    background: colors.grey1,
    click: colors.grey1,
    color: colors.grey6,
  },
  neutral: {
    active: colors.grey2,
    background: colors.grey1,
    click: colors.grey1,
    color: colors.grey6,
  },
  pending: {
    active: colors.grey2,
    background: colors.grey1,
    click: colors.grey1,
    color: colors.grey6,
  },
  light: {
    color: colors.blue10,
    active: colors.blue2,
  },
  default: {
    active: '#fff',
    background: '#fff',
    click: '#fff',
    color: '#000',
  },
};

export function getColorByAction(action: keyof IButtonTypeColors, type?: keyof IButtonTheme) {
  if (!type) return buttonTheme.default[action];
  return buttonTheme[type][action] || buttonTheme.default[action];
}

export function getBorderRadiusByShape(shape: keyof typeof ButtonShapes, size: keyof typeof ButtonSizes) {
  switch (shape) {
    case 'circle':
      return '100%';
    case 'rounded': {
      const sizeNumber = ButtonSizes[size] || ButtonSizes.medium;
      return `${sizeNumber / 2}px`;
    }
    default:
      return '0.5rem';
  }
}

const ButtonStyling = ({
  size = 'medium',
  shape = ButtonShapes.roundedsquare,
  type: propType = 'action',
}: IButtonBase) => {
  const sizeNumber = ButtonSizes[size] || ButtonSizes.medium;
  const type = buttonTheme[propType] ? propType : 'action';
  return css`
    background: ${getColorByAction('background', type)};
    transition: all 0.2s ease;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    font-size: ${sizeNumber <= ButtonSizes.small ? fonts.px12.size : fonts.px14.size};

    width: ${shape === 'circle' ? `${sizeNumber}px` : 'auto'};
    height: ${sizeNumber}px;
    border-radius: ${getBorderRadiusByShape(shape, size)};
    padding: ${shape === 'circle' ? 'initial' : '0.5rem 1rem'};
    ${type === 'link'
      ? css`
          padding: 0;
          height: auto;
        `
      : ''}
    :disabled {
      pointer-events: none;
      overflow: hidden;

      ::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        opacity: 0.5;
      }
    }

    :hover:not([disabled]) {
      background: ${getColorByAction('active', type)};
    }

    :active:not([disabled]) {
      background: ${getColorByAction('click', type)};
      transform-origin: 50% 50%;
      transform: scale(0.9);
    }

    :active:focus:not([disabled]) {
      background: ${getColorByAction('active', type)};
    }

    > span {
      color: ${getColorByAction('color', type)};
      font-family: ${fonts.bold};
      display: inline-flex;
      padding-right: 0.375rem;
      align-items: center;

      :last-child {
        padding-right: 0;
      }

      > svg path {
        fill: ${getColorByAction('color', type)};
      }
    }
  `;
};

export const Root = styled(
  forwardRef<HTMLButtonElement, IButtonProps>(
    ({ size, type, disabled, children, width, height, htmlType = 'button', ...restProps }, ref) => (
      <button type={htmlType} ref={ref} disabled={disabled} {...restProps}>
        {children}
      </button>
    )
  )
)`
  ${ButtonStyling}
`;

export const ButtonLink = styled(
  forwardRef<HTMLAnchorElement, IButtonLink>(({ size, type, to, children, ...restProps }, ref) => (
    <Link ref={ref} to={to} {...restProps}>
      {children}
    </Link>
  ))
)`
  ${ButtonStyling}
`;

export const IconButton = styled(Root)`
  > svg {
    path {
      fill: ${colors.blue9} !important;
    }
  }
`;

export const ActionButton = styled(Root)`
  > svg {
    path {
      fill: white !important;
    }
  }
`;

export const BackButton = styled(IconButton)`
  > svg {
    transform: rotate(180deg);
  }
`;

const Spinning = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.img`
  animation-name: ${Spinning};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  height: 20px;
  margin-right: 10px;
`;
