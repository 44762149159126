import { breakpoints } from 'Styles/theme';

export function getWindowWidth() {
  const html = document.querySelector('html');
  if (html) {
    return window.innerWidth / parseFloat(getComputedStyle(html)[<any>'font-size']);
  }
  return window.innerWidth;
}

export const isMobile = getWindowWidth() < breakpoints.sm;
export const isTablet = getWindowWidth() < breakpoints.md && getWindowWidth() > breakpoints.sm;
