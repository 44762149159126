export const space = {
  unit: '1rem',
  px4: '0.25rem',
  px8: '0.5rem',
  px12: '0.75rem',
  px16: '1rem',
  px20: '1.25rem',
  px24: '1.5rem',
  px32: '2rem',
  px40: '2.5rem',
  px48: '3rem',
  px58: '3.25rem',
  px84: '5.25rem',
};
