import { FC, memo } from 'react';
import latinize from 'latinize';

import UserIcon from 'Assets/svg/icons/user.svg';

import { getColor } from 'Libs/helpers/display';

import { AVATAR_DEFAULT_SIZE, AvatarSizes, IAvatarProps, IAvatarSource } from './Avatar.types';
import * as S from './Avatar.styles';

export const getInitials = ({ name, firstname, lastname, title }: IAvatarSource) => {
  let names = [];

  if (firstname && typeof firstname === 'string') {
    names.push(firstname);
  }
  if (lastname && typeof lastname === 'string') {
    names.push(lastname);
  }
  if (name && typeof name === 'string') {
    names.push(name);
  }
  if (title && typeof title === 'string') {
    names.push(title);
  }

  if (names.length === 1 && names[0].match(/\W/)) {
    names = names[0].split(' ');
  }
  return latinize(
    names
      .filter((text: any) => typeof text === 'string' && text.match(/\w/))
      .reduce((text: string, next: string) => (next && next[0] ? text + next[0].toUpperCase() : text), '')
      .substr(0, 2) || ''
  );
};

export const fixUrl = (url: string, width: number) =>
  url.replace(/image\/upload\/(.+\/)?v/, `image/upload/w_${width}/v`);

const Avatar: FC<IAvatarProps> = ({
  size = AVATAR_DEFAULT_SIZE,
  source = {},
  style,
  children,
  onClick,
  ...restProps
}) => {
  const { avatar, logo, coverImage } = source;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { secure_url } = avatar ?? logo ?? coverImage ?? source ?? {};

  const initials = getInitials(source);

  const avatarStyle = {
    background: getColor(initials),
    ...style,
  };

  const sizeNumber = typeof size === 'string' ? AvatarSizes[size] || AvatarSizes[AVATAR_DEFAULT_SIZE] : size;

  // Hackilly for now
  const secureUrlSmall = secure_url && fixUrl(secure_url, sizeNumber);
  const secureUrlLarge = secure_url && fixUrl(secure_url, sizeNumber * 2);

  return (
    <S.Root size={sizeNumber} style={avatarStyle} onClick={onClick} {...restProps}>
      {children ||
        (secure_url && (
          <S.Img srcSet={`${secureUrlSmall} 1x, ${secureUrlLarge} 2x`} src={secureUrlSmall} alt={initials} />
        )) ||
        (!secure_url && initials) || (
          <S.User>
            <UserIcon width="60%" />
          </S.User>
        )}
    </S.Root>
  );
};

export default memo<IAvatarProps>(Avatar);
