import { isArray, isUndefined, memoize as lodashMemo } from 'lodash';
import { DEFAULT_COUNTRY_CODE } from 'Constants/app';
import { DialCountries } from 'Libs/phoneDialCodes';
import React from 'react';

export function detectCountryCode() {
  const nL = navigator.language;
  const countryCode = nL.slice(nL.length - 2, nL.length).toLocaleLowerCase();
  return DialCountries[countryCode] ? countryCode : DEFAULT_COUNTRY_CODE;
}

export const isStagingEnv = () => Boolean(process.env.STAGING);

export const isLocalEnv = () => Boolean(process.env.LOCAL);

export const isAlphaEnv = () => Boolean(process.env.ALPHA);

export function memoAny<T extends (...args: any) => any>(func: T) {
  const keyResolver = (...args: Parameters<T>) => JSON.stringify(args);
  return lodashMemo(func, keyResolver);
}

export const createId = (): string => `_${Math.random().toString(36).substr(2, 9)}`;

export const injectProps = (children: any, props: Record<string, any>) => {
  if (isArray(children) && props) {
    return children.map((child, index) =>
      React.cloneElement(child, typeof props === 'function' ? props(index) : { ...props })
    );
  }
  if (!isArray(children) && props) {
    return React.cloneElement(children, typeof props === 'function' ? props() : { ...props });
  }
  return children;
};

export function noPropagation(e: React.MouseEvent<any>) {
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }
}

export function removeDuplicates<T>(data: T[], keys: (keyof T)[]) {
  const index = new Set();
  const rows = [];
  for (const row of data) {
    const v = keys.reduce((p, k) => p + row[k], '');
    if (!index.has(v)) {
      rows.push(row);
      index.add(v);
    }
  }
  return rows;
}

type Required<T> = { [P in keyof T]-?: Exclude<T[P], undefined> };
export function clean<T>(a: T): Required<T> {
  const r: any = {};
  for (const [k, v] of Object.entries(a)) {
    if (!isUndefined(v)) {
      r[k] = v;
    }
  }
  return r;
}

export function base64(str: any) {
  if (typeof btoa === 'function') {
    return btoa(str);
  }
  const buffer = str instanceof Buffer ? str : Buffer.from(str.toString(), 'binary');
  return buffer.toString('base64');
}
