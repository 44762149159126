import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Meteor, { usePublication } from 'API/Meteor';
import { UserBadges } from 'API/collections';
import {
  setGroupsRequestToJoin,
  setNewNotificationsCount,
  setNewThreadsCount,
  updatedBadgesAt,
} from 'Modules/core/actions';

type IBadge = {
  badge: string;
  value: any;
};

type IBadgeResult = {
  _id: string;
  updatedAt: Date;
  badges: IBadge[];
};

function badgeNewCount(data: IBadgeResult, badge: string) {
  return badgeValue(data, badge)?.new;
}

function badgeValue(data: IBadgeResult, badge: string) {
  return (data?.badges || []).find((b) => b.badge === badge)?.value;
}

export default function APISubscriptions() {
  const dispatch = useDispatch();
  const [data] = usePublication<IBadgeResult>({
    name: 'users.badges',
    fetch: () => UserBadges.findOne({ _id: Meteor.userId() }),
  });

  useEffect(() => {
    dispatch(updatedBadgesAt(new Date(data?.updatedAt).getTime()));
  }, [data?.updatedAt]);

  useEffect(() => {
    dispatch(setNewThreadsCount(badgeNewCount(data, 'threads')));
  }, [badgeNewCount(data, 'threads')]);

  useEffect(() => {
    dispatch(setNewNotificationsCount(badgeNewCount(data, 'notifications')));
  }, [badgeNewCount(data, 'notifications')]);

  useEffect(() => {
    dispatch(setGroupsRequestToJoin(badgeValue(data, 'groupsRequestsToJoin')));
  }, [JSON.stringify(badgeValue(data, 'groupsRequestsToJoin'))]);

  return <div />;
}
