import loadable from '@loadable/component';
import Loading from 'Interface/Loading';
import pMinDelay from 'p-min-delay';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import { PRELOADING_DELAY } from 'Constants/app';

import signUpReducers from 'Modules/signup/reducers';
import inviteReducers from 'Modules/invites/reducers';
import reducers from './reducers';

const Login = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light transparent />,
});

export default () => (
  <DynamicModuleLoader modules={[reducers(), signUpReducers(), inviteReducers()]}>
    <Login />
  </DynamicModuleLoader>
);
