import styled, { css, keyframes } from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

import { breakpoints, colors } from 'Styles/theme';

import { INotificationMessage } from '../../types';

export const Root = styled(Row)`
  transition: 0.3s ease all;
  position: fixed;
  bottom: 20px;
  opacity: 1;
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px);  }
  to { opacity: 1; transform: translateY(0); }
`;

export const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0px); }
  to { opacity: 0; transform: translateY(20px); }
`;

export const Button = styled.button`
  cursor: pointer;
  color: #fff;
  background: none;
  border: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const Bar = styled.div<Pick<INotificationMessage, 'type' | 'visible'>>`
  background: ${({ type }) => {
    switch (type) {
      case 'error': {
        return colors.red4;
      }
      case 'warning': {
        return colors.beige5;
      }
      default:
        return colors.green4;
    }
  }};

  margin-bottom: 1rem;
  animation: ${({ visible }) =>
    css`
      ${visible ? fadeIn : fadeOut} 0.3s ease
    `};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  @media screen and (max-width: ${breakpoints.sm}rem) {
    flex-direction: column;
    align-items: flex-start;

    ${Button} {
      display: none;
    }
  }

  & div {
    color: white;

    * {
      color: white;
    }

    a {
      text-decoration: underline;
    }
  }
`;

export const Message = styled.div``;
