import { ForwardedRef, HTMLProps, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

export enum ButtonTypes {
  link = 'link',
  action = 'action',
  positive = 'positive',
  negative = 'negative',
  ghost = 'ghost',
  secondary = 'secondary',
  'secondary-alt' = 'secondary-alt',
  neutral = 'neutral',
  pending = 'pending',
  light = 'light',
}

export enum ButtonHTMLTypes {
  submit,
  button,
  reset,
}

export enum ButtonSizes {
  large = 56,
  medium = 40,
  small = 30,
  xsmall = 20,
}

export enum ButtonShapes {
  circle = 'circle',
  rounded = 'rounded',
  roundedsquare = 'roundedsquare',
}

export type IHTMLButton = HTMLProps<HTMLButtonElement>;

export type IButtonBase = {
  icon?: ReactNode;
  iconRight?: ReactNode;
  size?: keyof typeof ButtonSizes;
  type?: keyof typeof ButtonTypes;
  htmlType?: keyof typeof ButtonHTMLTypes;
  shape?: keyof typeof ButtonShapes;
  spinner?: boolean;
};

export type IButtonProps = Omit<IHTMLButton, 'type' | 'as' | 'size'> &
  IButtonBase & {
    ref?: ForwardedRef<HTMLButtonElement>;
  };

export type IButtonTypeColors = {
  active?: string;
  background?: string;
  click?: string;
  color?: string;
};

export type IButtonTheme = Record<Required<IButtonProps>['type'], IButtonTypeColors> &
  Record<'default', Required<IButtonTypeColors>>;

export type IButtonLink = LinkProps & Omit<IButtonBase, 'htmlType'>;
