import { PayloadAction } from '@reduxjs/toolkit';
import { toggleFilter } from 'Interface/FilterMenu';

import { createReduxState } from 'Libs/helpers';

type IGroupsState = {
  keyword: string;
  total: number;
  forceUpdate: number;
  groupMembersFilter: Record<string, any>;
};

const initialState: IGroupsState = {
  keyword: '',
  total: 0,
  forceUpdate: 0,
  groupMembersFilter: {},
};

export const {
  reducers,
  actions,
  useSelector: useGroupsSelector,
} = createReduxState({
  name: 'groups',
  initialState,
  reducers: {
    setKeyword(state, { payload: keyword }: PayloadAction<string>) {
      state.keyword = keyword;
    },
    setTotal(state, { payload: total }: PayloadAction<number>) {
      state.total = total;
    },
    setForceUpdate(state) {
      state.forceUpdate = Date.now();
    },
    setGroupMembersFilter(state, { payload: filter }: PayloadAction<Record<string, any>>) {
      const newFilter = toggleFilter(filter, state.groupMembersFilter);
      state.groupMembersFilter = newFilter;
    },
    resetGroupMembersFilter() {
      return { ...initialState };
    },
  },
});
