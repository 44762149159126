import styled, { css } from 'styled-components';
import { R14 } from 'Styles/Typography';
import { colors, space } from 'Styles/theme';
import CssToObject from 'Libs/CssToObject';

export const Divider = styled.div`
  height: 1px;
  background: ${colors.grey1};
  margin: 0 ${space.px4};
`;

export const Item = styled(R14())`
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: ${space.px12};
  margin: ${space.px4};
  justify-content: space-between;
  //min-width: 250px;

  &:hover {
    background: ${colors.grey0};
  }

  & svg path {
    fill: ${colors.blue9};
  }

  &.warning {
    & svg path {
      fill: ${colors.red4};
    }

    & div {
      color: ${colors.red4};
    }

    &:hover {
      background: ${colors.red1};
      &::after {
        visibility: hidden;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  margin-left: ${space.px8};
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  width: 18px;
  justify-content: center;
`;

export const DropDownStyle = CssToObject(css`
  border: 0 none;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow: auto;
`);
