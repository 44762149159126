import { MeteorCall } from 'API/Meteor';
import { useSelector } from 'react-redux';
import { isMobile, isTablet } from 'Libs/helpers/window';
import { ICoreModuleState } from 'Modules/core/types';

export default function useNotificationCenter() {
  const updatedAt = useSelector((state: ICoreModuleState) => state.badges.updatedAt);
  const unseen = useSelector((state: ICoreModuleState) => state.badges.newNotificationsCount);

  const notifyNotificationsSeen = async () => {
    await MeteorCall('notifications.seen');
  };

  return {
    unseen,
    ready: updatedAt && updatedAt > 0,
    mobile: isMobile,
    tablet: isTablet,
    notifyNotificationsSeen,
  };
}
