import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import Loading from 'Interface/Loading';

import { PRELOADING_DELAY } from 'Constants/app';

import inviteReducers from 'Modules/invites/reducers';
import reducers from './reducers';

const SignUp = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light transparent />,
});

export default () => (
  <DynamicModuleLoader modules={[reducers(), inviteReducers()]}>
    <SignUp />
  </DynamicModuleLoader>
);
