import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints, colors } from 'Styles/theme';

export const DEFAULT_HEIGHT = 56;

export const Label = styled.div``;

export const Button = styled(({ active, children, ...props }) => <Link {...props}>{children}</Link>)<{
  active?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  cursor: pointer;

  &:hover {
    & svg path {
      fill: ${colors.blue10};
    }
  }

  & svg path {
    fill: ${(props) => (props.active ? colors.blue10 : colors.grey6)};
  }

  ${Label} {
    margin: 4px 0 0 0;
    font-size: 12px;
    color: ${(props) => (props.active ? colors.blue10 : colors.grey6)};
  }

  @media only screen and (min-width: ${breakpoints.md}em) {
    width: 48px;
    margin: 0 16px;
    border-bottom: ${(props) => (props.active ? `2px solid ${colors.blue10}` : '2px solid transparent')};

    ${Label} {
      display: none;
      position: absolute;
      top: 100%;
      background: ${colors.blue11};
      color: #fff;
      font-size: 0.75rem;
    }

    :hover {
      ${Label} {
        display: block;
        margin-top: -1px;
        border-radius: 3px;
        padding: 2px 5px;
        min-width: 100%;
        text-align: center;
      }
    }
  }
`;
