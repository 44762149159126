import styled, { css } from 'styled-components';
import Loading from 'Interface/Loading';
import { breakpoints, colors, fonts } from 'Styles/theme';
import { R14 } from 'Styles/Typography';

export const ModalRoot = styled.div`
  border-radius: 8px;
  background: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  min-width: 472px;
  max-width: 472px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 0;

  @media only screen and (max-width: ${breakpoints.sm}em) {
    border-radius: 0;
    width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const ModalLoading = styled(Loading)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const HeaderRoot = styled.div<{ noShadow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: #fff;
  position: relative;

  ${({ noShadow }) =>
    !noShadow
      ? css`
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        `
      : ''};

  h1,
  h2 {
    color: ${colors.blue9};
  }
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  z-index: -1;
  overflow: auto;
`;

export const Center = styled.div`
  text-align: center;
  flex-grow: 1;
`;

export const Small = styled(R14())`
  color: ${colors.grey5};
`;

export const Content = styled.div<{ maximize?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ maximize }) =>
    maximize
      ? css`
          flex: 1 1 100vh;
        `
      : css`
          flex-grow: 1;
        `}
  z-index: -1;
  overflow: auto;

  .link-copy {
    font-family: ${fonts.bold};
    background-color: ${colors.green1};
    color: ${colors.green5};
    font-size: ${fonts.px14.size};
    line-height: ${fonts.px14.height};
  }

  .__react_component_tooltip {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-out !important;
    opacity: 0 !important;
    visibility: visible;
  }

  .__react_component_tooltip.show {
    visibility: visible;
    opacity: 1 !important;
  }
`;
