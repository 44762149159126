import { matchPath } from 'react-router-dom';

import groups from './uri/groups';
import units from './uri/units';
import messages from './uri/messages';
import posts from './uri/posts';
import requests from './uri/requests';
import user from './uri/user';

const NybyUri = {
  ...groups,
  ...units,
  ...messages,
  ...posts,
  ...requests,
  ...user,

  home() {
    return '/home';
  },

  relative(url: string | undefined, defaultUrl = '/') {
    if (url) {
      try {
        const uri = new URL(url);
        return uri.pathname + uri.search + uri.hash;
      } catch (e) {
        console.warn(`Not able to parse url "${url}": ${e}`);
      }
    }
    return url ?? defaultUrl;
  },

  isAppUrl(url: string) {
    if (url) {
      try {
        const uri = new URL(url);
        return uri.hostname.match(/^([\w-]+\.)?app.nyby.com$/);
      } catch (e) {
        console.warn(`Not able to parse url "${url}": ${e}`);
      }
    }
    return false;
  },
};

export function handleRoutes(path: string | undefined, routes: Record<string, (params?: any) => boolean>) {
  for (const [pattern, method] of Object.entries(routes)) {
    const match = matchPath(pattern, `/${path}`);
    if (match && method(match.params as any)) {
      return true;
    }
  }
  return false;
}

export default NybyUri;
