import styled, { css } from 'styled-components';
import { Badge } from 'Interface/Badge/Badge.styles';
import CssToObject from 'Libs/CssToObject';
import { breakpoints, colors } from 'Styles/theme';

export const Button = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 40px;
  border-bottom: ${(props) => (props.active ? `2px solid ${colors.blue10}` : 0)};
  cursor: pointer;

  &:hover {
    & svg path {
      fill: ${colors.blue10};
    }
  }

  & svg path {
    fill: ${(props) => (props.active ? colors.blue10 : colors.grey6)};
  }

  & > div {
    margin: 4px 0 0 0;
    font-size: 12px;
  }

  @media only screen and (min-width: ${breakpoints.md}em) {
    width: 72px;
  }
  @media only screen and (max-width: ${breakpoints.md}em) {
    ${Badge} {
      top: -11px;
    }
  }
`;

export const dropDownStyle = CssToObject(css`
  border: 0 none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 280px;
  max-height: 80vh;
  margin-right: 20px;
`);

export const mobileStyle = CssToObject(css`
  border: 0 none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  transform: translate3d(0px, 0px, 0px) !important;
`);

export const tabletStyle = CssToObject(css`
  border: 0 none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 280px;
  max-height: 80vh;
  margin-right: 4px;
`);
