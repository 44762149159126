import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBadgesState, IGroupsRequestToJoin } from '../types';

export const initialState: IBadgesState = {
  updatedAt: 0,
  newThreadsCount: 0,
  newNotificationsCount: 0,
  groupsRequestsToJoin: null,
};

const badges = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    updatedBadgesAt(state, { payload: updatedAt }: PayloadAction<number>) {
      state.updatedAt = updatedAt;
    },
    setNewThreadsCount(state, { payload: newThreadsCount }: PayloadAction<number>) {
      state.newThreadsCount = newThreadsCount;
    },
    setNewNotificationsCount(state, { payload: newNotificationsCount }: PayloadAction<number>) {
      state.newNotificationsCount = newNotificationsCount;
    },
    setGroupsRequestToJoin(state, { payload: groupsRequestsToJoin }: PayloadAction<IGroupsRequestToJoin>) {
      state.groupsRequestsToJoin = groupsRequestsToJoin;
    },
  },
});

const { reducer, actions } = badges;

export { actions };
export default reducer;
