import { ReactElement } from 'react';

// import { IThread } from 'API/types';

export type ICoreModuleState = {
  modal: IModalState;
  notificationBar: INotificationState;
  badges: IBadgesState;
  buckets: IBucketsState;
};

export type IBucketsState = {
  values: Record<string, any>;
};

export type IModalStack = {
  dialog: any;
  props: any;
  focus: boolean;
};

export type IModalState = {
  visible: boolean;
  stack: IModalStack[];
  stackIndex: number;
};

export enum NotificationTypes {
  info,
  warning,
  error,
}

export type INotificationMessage = {
  id: string;
  message: ReactElement;
  visible?: boolean;
  label?: string | null;
  type?: keyof typeof NotificationTypes;
  /** notification hide timeout, default = 5000 */
  timeout?: number;
};

export type INotificationState = {
  messages: INotificationMessage[];
};

export type INotificationShowAction = {
  payload: Omit<INotificationMessage, 'id'> | INotificationMessage['message'];
};

export type INotificationHideAction = {
  payload: string[];
};

type IGroupsRequestToJoinGroup = {
  groupId: string;
  requestsToJoinCount: number;
  teams: { teamId: string; requestsToJoinCount: number }[];
};

export type IGroupsRequestToJoin = {
  total: number;
  groups: IGroupsRequestToJoinGroup[];
};

export type IBadgesState = {
  updatedAt: number;
  newThreadsCount: number;
  newNotificationsCount: number;
  groupsRequestsToJoin: IGroupsRequestToJoin | null;
};
