import React from 'react';
import { useNavigate } from 'react-router-dom';
import Meteor, { deleteUserToken, MeteorCall } from 'API/Meteor';
import SettingsIcon from 'Assets/svg/icons/settings.svg';
import LeaveIcon from 'Assets/svg/icons/leave.svg';
import { i18n } from 'Configs/i18n';
import { IMenuItem } from 'Interface/DropDownMenu';

export default function useUserProfile() {
  const user = Meteor.user();
  const navigate = useNavigate();

  const logout = async () => {
    await MeteorCall('logout');
    navigate('/login');
    deleteUserToken();
  };

  const actions: IMenuItem[] = [
    {
      label: i18n.__('user.settings'),
      icon: <SettingsIcon height={18} />,
      action: () => navigate('/settings'),
      className: 'link',
    },
    { divider: true },
    {
      label: i18n.__('user.sign_out'),
      icon: <LeaveIcon height={18} />,
      action: () => logout().then(),
      className: 'warning',
    },
  ];

  return {
    user,
    actions,
  };
}
