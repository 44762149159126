import styled from 'styled-components';

import { breakpoints, colors, space } from 'Styles/theme';
import { B14, B16, R14 } from 'Styles/Typography';

export const Header = styled(B16())`
  display: flex;
  align-items: center;
  padding: ${space.px16};
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
`;

export const Scroll = styled.div`
  overflow: auto;
  flex: 1 1 100vh;
  @media only screen and (max-width: ${breakpoints.sm}em) {
    flex: initial;
  }
`;

export const Item = styled.div<{ $status: string }>`
  cursor: pointer;
  background: ${({ $status }) => ($status === 'NEW' ? colors.blue2 : 'white')};
  display: flex;
  color: ${colors.grey5};
  &:hover {
    background: ${colors.grey0};
  }
`;

export const Content = styled(R14())`
  margin-left: ${space.px12};
`;

export const Notification = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 ${space.px8};
  padding: ${space.px8};
  border-top: 1px solid ${colors.grey0};
`;

export const Name = styled(B14())`
  color: ${colors.grey6};
  margin-bottom: ${space.px4};
`;

export const Body = styled.div``;

export const Date = styled.div`
  margin-top: ${space.px4};
  color: ${colors.grey4a};
`;

export const HeaderCross = styled.div`
  transition: all 0.3s ease;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  & svg {
    width: 13px;
    height: 13px;
  }

  @media only screen and (max-width: ${breakpoints.md}em) {
    width: 30px;
    height: 30px;
    background: ${colors.grey0};

    &:hover {
      background: ${colors.grey1};
    }
  }

  @media only screen and (max-width: ${breakpoints.sm}em) {
    width: 40px;
    height: 40px;
    background: ${colors.grey0};

    & svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background: ${colors.grey1};
    }
  }

  & svg path {
    fill: ${colors.cobalt10};
  }
`;

export const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;
