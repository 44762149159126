import { IModule } from 'redux-dynamic-modules';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selector, useSelector as useReduxSelector } from 'react-redux';

import { ISignUpAction, ISignUpModuleState, ISignUpState } from './types';

export const initialState: ISignUpState = {
  userId: '',
  token: '',
  emailRequired: true,
};

const { reducer, actions } = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setUserData(state, { payload: { userId, token } }: ISignUpAction) {
      state.userId = userId;
      state.token = token;
    },
    setEmailRequired(state, { payload: emailRequired }: PayloadAction<boolean | undefined>) {
      state.emailRequired = emailRequired;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export { actions };

export function useSelector<T>(selector: Selector<ISignUpModuleState, T>) {
  return useReduxSelector<ISignUpModuleState, T>(selector);
}

export default () =>
  ({
    id: 'signUp',
    reducerMap: {
      signUp: reducer,
    },
  } as IModule<ISignUpModuleState>);
