import { MouseEvent, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import * as S from './ModalManager.styles';
import useModalManager from './useModalManager';

type IEscape = {
  onEscape: (event: MouseEvent<HTMLDivElement> | KeyboardEvent, force?: boolean) => void;
  focus: boolean;
};

/**
 * Adds keyboard events for modal
 */

function EventManager({ children, onEscape, focus }: PropsWithChildren<IEscape>) {
  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        onEscape(event, true);
      }
    };
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [focus]);
  return <div>{children}</div>;
}

export default function ModalManager() {
  const { stack, stackIndex, closeModal, pop, overlayRef, visible, containerRef } = useModalManager();

  const renderModal = () => {
    const Dialog = stack ? stack[stackIndex - 1].dialog : null;
    const dialogProps = stack ? stack[stackIndex - 1].props : {};
    const focus = stack ? stack[stackIndex - 1].focus : false;
    const noOp = () => null;
    return (
      <EventManager onEscape={focus ? pop : closeModal} focus={focus}>
        {/* <Prompt message={i18n.__('global.are_you_sure_you_want_to_leave')} when={!!stack.length} /> */}
        <S.Overlay onClick={focus ? noOp : closeModal} ref={overlayRef}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ flexShrink: 1 }}>
            <FocusLock>
              <Dialog {...dialogProps} />
            </FocusLock>
          </div>
          <div style={{ flexGrow: 2 }} />
        </S.Overlay>
      </EventManager>
    );
  };

  return visible ? createPortal(renderModal(), containerRef.current as Element) : null;
}
