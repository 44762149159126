import styled, { keyframes, css } from 'styled-components';

const Spinning = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Root = styled.div<{ inline: boolean; transparent: boolean; light: boolean }>`
  animation-duration: 0.3s;
  animation-timing-function: ease;

  ${({ inline }) =>
    inline
      ? css`
          position: relative;
          display: inline-flex;
          vertical-align: middle;
        `
      : css`
          display: flex;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        `};

  background-color: ${({ transparent, light }) =>
    transparent ? 'none' : light ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.5)'};

  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const Spinner = styled.img`
  animation-name: ${Spinning};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
