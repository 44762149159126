import styled from 'styled-components';
import { breakpoints, colors, space } from 'Styles/theme';
import { R12 } from 'Styles/Typography';

export const Divider = styled.div`
  height: 1px;
  background: ${colors.grey1};
  margin: 0 ${space.px16};
`;

export const Header = styled.div`
  display: flex;
  margin: ${space.px16} ${space.px20};
`;

export const Profile = styled.div`
  margin-left: ${space.px12};
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${space.px8};
  cursor: pointer;

  > div {
    cursor: pointer;
  }

  @media only screen and (min-width: ${breakpoints.md}em) {
    flex-direction: row;
    margin: 0;
  }
`;

export const Name = styled(R12())`
  @media only screen and (min-width: ${breakpoints.md}em) {
    margin-left: ${space.px8};
  }
`;
