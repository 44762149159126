import Meteor from 'API/Meteor';
import { useSessionStorage } from 'react-use';

const PREFIX = 'Nyby:';

export function clearCache() {
  Object.keys(sessionStorage)
    .filter((k) => k.startsWith(PREFIX))
    .map((k) => sessionStorage.removeItem(k));
}

export default function useCache<T>(key: string, initialValue: T) {
  const userKey = `${PREFIX}${Meteor.userId()}:${key}`;
  return useSessionStorage(userKey, initialValue);
}
