import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

import Loading from 'Interface/Loading';

import { PRELOADING_DELAY } from 'Constants/app';

export default loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light />,
});
