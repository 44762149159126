import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'Modules/login';
import SignUp from 'Modules/signup';
import Download from 'Modules/download';
import Invites from 'Modules/invites';
import { isLocalEnv } from 'Libs/helpers';
import TestUserLogin from './TestUserLogin';

const NoAuth = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/login" replace />} />
    <Route path="/login/*" element={<Login />} />
    {isLocalEnv() && <Route path="/tester-login/:id" element={<TestUserLogin />} />}
    <Route path="/signup" element={<SignUp />} />
    <Route path="/download" element={<Download />} />
    <Route path="/invites/*" element={<Invites />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default NoAuth;
