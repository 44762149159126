import styled from 'styled-components';

import { breakpoints } from 'Styles/theme';

import BaseTopBar, { DEFAULT_HEIGHT } from './TopBar';

export const TopBar = styled(BaseTopBar)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const Container = styled.div`
  // max-width: ${breakpoints.maxWidth}px;
  margin: ${DEFAULT_HEIGHT}px auto 0 auto;
`;
