export const grey0 = '#f5f5f5';
export const grey1 = '#ebecec';
export const grey2 = '#d8d8d8';
export const grey3 = '#c5c5c6';
export const grey4 = '#b1b1b2';
export const grey4a = '#9E9E9F';
export const grey5 = '#636466';
export const grey6 = '#3d3e40';

export const blue1 = '#f2f8ff';
export const blue2 = '#e6f1ff';
export const blue3 = '#97dbfb';
export const blue9 = '#1360A1';
export const blue10 = '#0078ff';
export const blue11 = '#006be6';

export const beige3 = '#fff7f0';
export const beige5 = '#faede1';
export const beige6 = '#f7e8da';
export const beige7 = '#F5E3D3';
export const beige8 = '#f2decb';
export const beige10 = '#edd4be';

export const orange1 = '#ffebcc';
export const orange10 = '#e78e00';

export const red1 = '#FFE0E0';
export const red4 = '#ff6767';
export const red5 = '#D66561';

export const cobalt10 = '#1360a1';

export const green1 = '#ccede8';
export const green3 = '#0BD9BB';
export const green4 = '#00a88f';
export const green5 = '#008f79';

export const overlayBlack = 'rgba(0, 0, 0, 0.5)';
export const overlayWhite = 'rgba(255, 255, 255, 0.5)';
