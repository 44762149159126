import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';

import useDropDown from 'Interface/DropDown/useDropDown';
import { injectProps } from 'Libs/helpers';

import { IDropDownItem, IDropDownProps } from './DropDown.types';
import * as S from './DropDown.styles';

export default function DropDown(props: PropsWithChildren<IDropDownProps>) {
  const {
    children,
    placement,
    offset,
    data = [],
    dataItem = () => false,
    style,
    dropDownStyle,
    header,
    open = false,
    openOnClick = true,
    dataComponent = null,
    closeOnClick = true,
    closeOnClickOutside = true,
    showOverlay = false,
    onOpen = () => null,
    onClose = () => null,
    onVisibility = () => null,
    update,
    arrow,
    id,
    sameWidth,
  } = props;
  const { visible, handleDropDownClick, setReferenceRef, styles, setPopperRef, setArrowRef, attributes, onItemClick } =
    useDropDown({
      open,
      placement,
      offset,
      openOnClick,
      closeOnClick,
      closeOnClickOutside,
      onOpen,
      onClose,
      update,
      sameWidth,
      onVisibility,
    });

  const renderData = () => (
    <>
      {header && header()}
      {data.map((d: IDropDownItem, i) => dataItem(d, i))}
    </>
  );

  const renderContent = (state: any) => (
    <>
      <S.Overlay key="overlay" showOverlay={showOverlay} visible={visible} />
      <S.Container
        key="container"
        data-testid="DropDown__Container"
        id={id}
        ref={setPopperRef}
        style={{
          ...styles.popper,
          ...dropDownStyle,
          ...S.defaultStyle,
          ...S.transitionStyles(dropDownStyle)[state],
        }}
        {...attributes.popper}
        onClick={onItemClick}
      >
        {arrow && <S.Arrow ref={setArrowRef} style={styles.arrow} />}
        <div style={{ overflow: 'auto', maxHeight: 'inherit' }}>
          {!dataComponent && renderData()}
          {visible && dataComponent}
        </div>
      </S.Container>
    </>
  );

  return (
    <>
      <S.Target
        ref={setReferenceRef}
        onClick={handleDropDownClick}
        style={{ cursor: 'pointer', ...style }}
        tabIndex={0}
      >
        {injectProps(children, { visible })}
      </S.Target>
      <Transition in={visible} timeout={S.duration}>
        {(state: any) => {
          if (typeof document !== 'undefined') {
            const doc = document.querySelector('#portal');
            if (doc) {
              return createPortal(renderContent(state), doc);
            }
          }
        }}
      </Transition>
    </>
  );
}
