import { IModule } from 'redux-dynamic-modules';
import { createSlice } from '@reduxjs/toolkit';
import { Selector, useSelector as useReduxSelector } from 'react-redux';

import { IInvitesModuleState, IInvitesState, IInvitesAction } from './types';

export const initialState: IInvitesState = {
  inviteId: '',
  role: 'null',
};

const { reducer, actions } = createSlice({
  name: 'invites',
  initialState,
  reducers: {
    setInviteData(state, { payload: { inviteId, role } }: IInvitesAction) {
      state.inviteId = inviteId;
      state.role = role;
    },
  },
});

export { actions };

export function useSelector<T>(selector: Selector<IInvitesModuleState, T>) {
  return useReduxSelector<IInvitesModuleState, T>(selector);
}

export default () =>
  ({
    id: 'invites',
    reducerMap: {
      invites: reducer,
    },
  } as IModule<IInvitesModuleState>);
