import moment from 'moment';
import { IMessage } from 'API/types';

export function dateFromNow(date: moment.MomentInput) {
  return moment(date).fromNow();
}

export function longDateTime(date: moment.MomentInput) {
  return moment(date).format('ddd D. MMMM, HH:mm');
}

export function shortDateTime(date: moment.MomentInput) {
  return moment(date).format('D. MMM. HH:mm');
}

export function shortDate(date: moment.MomentInput) {
  const dateFormat = {
    sameDay: 'LT',
    nextDay: 'MMM D',
    nextWeek: 'MMM D',
    lastDay: 'MMM D',
    lastWeek: 'MMM D',
    sameElse: 'MMM D',
  };

  return moment(date).calendar(dateFormat);
}

export function formatMessageDate(message: IMessage) {
  return shortDateTime(message.createdAt);
}

export function monthYear(date: moment.MomentInput) {
  return moment(date).format('MMMM YYYY');
}
