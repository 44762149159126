import { createGlobalStyle } from 'styled-components';
import { colors, theme } from 'Styles/theme';
import { IStyleProps } from 'Styles/Typography';

import MessinaRegularWoff from 'Assets/fonts/MessinaSans-Regular.woff';
import MessinaRegularWoff2 from 'Assets/fonts/MessinaSans-Regular.woff2';

import MessinaBoldWoff from 'Assets/fonts/MessinaSans-SemiBold.woff';
import MessinaBoldWoff2 from 'Assets/fonts/MessinaSans-SemiBold.woff2';

// generate default css from theme
const globalCssForThemes = (themes: IStyleProps[]) => {
  const lines = [];
  const inline = (prop: string, v?: any) => (v ? `${prop}:${Array.isArray(v) ? v[0] : v};` : '');
  for (const style of themes) {
    if (style.tag) {
      const rules = [
        inline('font-family', style.$fontFamily),
        inline('font-style', style.$fontStyle),
        inline('font-weight', style.$fontWeight),
        inline('font-size', style.$fontSize),
        inline('line-height', style.$lineHeight),
      ];
      lines.push(`${style.tag}{${rules.join('\n')}}`);
    }
  }
  return lines.join('\n');
};

const withTags = (Object.values(theme) as IStyleProps[]).filter((t) => t.tag);
const GlobalStyle = globalCssForThemes(withTags);

export const Global = createGlobalStyle`
  @font-face {
    font-family: 'MessinaSans-Regular';
    src: local('MessinaSans-Regular'),
    url(${MessinaRegularWoff2}) format('woff2'),
    url(${MessinaRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-FB04;
  }

  @font-face {
    font-family: 'MessinaSans-SemiBold';
    src: local('MessinaSans-SemiBold'),
    url(${MessinaBoldWoff2}) format('woff2'),
    url(${MessinaBoldWoff}) format('woff');

    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-FB04;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    white-space: pre-line;
  }

  body, textarea, input, td, button, p {
    line-height: ${theme.r16.$lineHeight};
    font-family: ${theme.r16.$fontFamily};
    font-size: ${theme.r16.$fontSize};
    color: ${colors.grey6};
  }

  p {
    text-size-adjust: none;
  }

  html {
    &.overflow {
      body {
        overflow: hidden !important;
        height: 100%;
      }
    }
  }
  
  ${GlobalStyle}
`;

export default Global;
