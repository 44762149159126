import { forwardRef } from 'react';

import DotsIcon from 'Assets/svg/icons/dots.svg';
import CrossIcon from 'Assets/svg/icons/cross.svg';
import SendIcon from 'Assets/svg/icons/send.svg';
import ArrowIcon from 'Assets/svg/icons/arrow-with-dash.svg';

import WhiteSpinner from 'Assets/png/loading-white.png';

import { IButtonLink, IButtonProps } from './Button.types';
import * as S from './Button.styles';

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ spinner, icon, iconRight, children, ...restProps }, ref) => (
    <S.Root ref={ref} {...restProps}>
      {spinner && <S.Spinner src={WhiteSpinner} />}
      {!spinner && icon && <span>{icon}</span>}
      {children ? <span>{children}</span> : null}
      {!spinner && iconRight && <span>{iconRight}</span>}
    </S.Root>
  )
);

export default Button;

export const ButtonLink = forwardRef<HTMLAnchorElement, IButtonLink>(
  ({ icon, iconRight, children, role, ...restProps }, ref) => (
    <S.ButtonLink ref={ref} role={role || 'button'} {...restProps}>
      {icon && <span>{icon}</span>}
      {children ? <span>{children}</span> : null}
      {iconRight && <span>{iconRight}</span>}
    </S.ButtonLink>
  )
);

export const OptionsButton = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <Button ref={ref} type="ghost" shape="circle" {...props}>
    <DotsIcon width={18} />
  </Button>
));

export const BackButton = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <S.BackButton ref={ref} type="ghost" shape="circle" {...props}>
    <ArrowIcon width={18} />
  </S.BackButton>
));

export const CloseButton = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <S.IconButton ref={ref} type="ghost" shape="circle" {...props} style={{ position: 'relative', zIndex: 1000000 }}>
    {props.icon ?? <CrossIcon width={18} />}
  </S.IconButton>
));

export const SendButton = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => (
  <S.ActionButton ref={ref} type="action" shape="circle" {...props}>
    <SendIcon width={18} />
  </S.ActionButton>
));
