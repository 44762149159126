import { IModalStack } from '../types';
import * as Actions from './actionTypes';

import { actions as notificationBarActions } from '../reducers/notificationBar';

import { actions as badgesActions } from '../reducers/badges';

const { showNotificationBar, hideNotificationBar, removeNotificationBar } = notificationBarActions;
export { showNotificationBar, hideNotificationBar, removeNotificationBar };
const { updatedBadgesAt, setNewThreadsCount, setNewNotificationsCount, setGroupsRequestToJoin } = badgesActions;
export { updatedBadgesAt, setNewThreadsCount, setNewNotificationsCount, setGroupsRequestToJoin };

export function pushModal(dialog: IModalStack['dialog'], props?: IModalStack['props'], focus?: IModalStack['focus']) {
  return {
    type: Actions.PUSH_MODAL,
    dialog,
    props,
    focus,
  };
}

export function popModal() {
  return {
    type: Actions.POP_MODAL,
  };
}

export function clearModalStack() {
  return {
    type: Actions.CLEAR_MODAL_STACK,
  };
}
