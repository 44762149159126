import { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from 'Styles/theme';

type IRoot = {
  showOverlay?: boolean;
  visible?: boolean;
  ref?: any;
  style?: CSSStyleDeclaration;
  onClick?: () => void;
};

export const Target = styled.div<{ ref: any }>``;

export const Overlay = styled.div<IRoot>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: ${({ showOverlay, visible }) => (showOverlay && visible ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Arrow = styled.div<{ ref: any }>`
  height: 8px;
  width: 24px;
  overflow: hidden;

  :before {
    content: ' ';
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    width: 12px;
    height: 12px;
    display: block;
    transform: rotate(45deg);
    background: white;
    border-radius: 3px;
    margin: 3px auto 0;
  }
`;

export const Container = styled.div<{ ref: any }>`
  border: 1px solid ${colors.beige8};
  border-radius: 8px;
  background: white;
  text-align: left;
  position: relative;
  z-index: 2000;

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -8px;
  }
`;

export const duration = 150;

export const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

export const transitionStyles = (dropDownStyle: CSSProperties): { [id: string]: CSSProperties } => ({
  entering: {
    opacity: 1,
    visibility: 'visible',
    maxHeight: dropDownStyle?.maxHeight ? dropDownStyle?.maxHeight : '40vh',
  },
  entered: {
    opacity: 1,
    visibility: 'visible',
    maxHeight: dropDownStyle?.maxHeight ? dropDownStyle?.maxHeight : '40vh',
  },
  exiting: { opacity: 0, visibility: 'hidden', maxHeight: 0 },
  exited: { opacity: 0, visibility: 'hidden', maxHeight: 0 },
});
