import { useDispatch } from 'react-redux';
import { clearModalStack, popModal } from 'Modules/core/actions';

export default function useModalInternal() {
  const dispatch = useDispatch();

  const onBackCallback = (onBack: any) => {
    if (typeof onBack === 'function') {
      return onBack;
    }
    if (onBack === true) {
      return () => {
        dispatch(popModal());
      };
    }
    return undefined;
  };

  const onClearModalStack = () => {
    dispatch(clearModalStack());
  };

  return {
    onBackCallback,
    onClearModalStack,
  };
}
