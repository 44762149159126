export enum BadgePlacements {
  topRight = 'topRight',
  bottomRight = 'bottomRight',
}

export type IBadgeProps = {
  count?: number;
  showZero?: boolean;
  placement?: keyof typeof BadgePlacements;
  offset?: [number, number];
  visible?: boolean;
};
