import { CSSProperties, MouseEventHandler } from 'react';

export const AVATAR_DEFAULT_SIZE = 'medium';

export enum AvatarSizes {
  tiny = 17,
  xsmall = 20,
  small = 30,
  medium = 40,
  large = 56,
  xlarge = 72,
  xxlarge = 96,
}

export type IAvatarSource = {
  _id?: string;
  firstname?: string;
  lastname?: string;
  name?: string;
  avatar?: any;
  logo?: any;
  coverImage?: any;
  title?: string;
};

export type IAvatarProps = {
  size?: keyof typeof AvatarSizes | number;
  source?: IAvatarSource;
  style?: CSSProperties;
  onClick?: MouseEventHandler;
};
