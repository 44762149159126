import { Navigate, Route, Routes } from 'react-router-dom';

import Download from 'Modules/download';

const NonAdmin = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/download" replace />} />
    <Route path="/download" element={<Download />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default NonAdmin;
