import styled from 'styled-components';

import Button from 'Interface/Button';

import { B20, R16 } from 'Styles/Typography';
import { colors } from 'Styles/theme';

export const Root = styled.div`
  text-align: center;
`;

export const Header = styled(B20())`
  color: ${colors.cobalt10};
  margin-top: 12px;
`;

export const Content = styled(R16())`
  margin-top: 12px;
  padding: 0 60px;
  text-align: justify;
`;

export const Ok = styled(Button)`
  margin: 24px 0 48px 0;
`;
