import { HTMLProps } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useSelector } from 'react-redux';

import NybyUri from 'API/NybyUri';
import { i18n } from 'Configs/i18n';

import NybyLogo from 'Assets/svg/nyby-logo.svg';
import HomeIcon from 'Assets/svg/icons/home.svg';
import HomeActiveIcon from 'Assets/svg/icons/home-active.svg';
import GroupsIcon from 'Assets/svg/icons/groups.svg';
import GroupsActiveIcon from 'Assets/svg/icons/groups-active.svg';
import MembersIcon from 'Assets/svg/icons/members.svg';
import MessagesIcon from 'Assets/svg/icons/message.svg';
import MessagesActiveIcon from 'Assets/svg/icons/message-active.svg';

import { ICoreModuleState } from 'Modules/core/reducers';

import Meteor from 'API/Meteor';
import RightSection from './RightSection';
import NavButton from './NavButton';

import * as S from './TopBar.styles';

export default function TopBar({ className }: HTMLProps<HTMLDivElement>) {
  const { showMembersTab = false } = Meteor.user() ?? {};
  const newThreadsCount = useSelector((state: ICoreModuleState) => state.badges.newThreadsCount);
  const requestsToJoin = useSelector((state: ICoreModuleState) => state.badges.groupsRequestsToJoin);
  const { total: requestsToJoinCount = 0 } = requestsToJoin ?? {};
  return (
    <S.Root className={className}>
      <Row>
        <Col xs={false} sm={3} md={3}>
          <S.Bar middle="xs">
            <S.Logo to="/">
              <NybyLogo height={32} />
            </S.Logo>
            {/* <SearchInput /> */}
          </S.Bar>
        </Col>
        <Col xs={10} sm={6}>
          <S.Bar center="sm" middle="xs">
            <NavButton
              label={i18n.__('global.home')}
              to={NybyUri.home()}
              icon={<HomeIcon height={25} />}
              activeIcon={<HomeActiveIcon height={25} />}
            />
            <NavButton
              label={i18n.__('global.groups')}
              to={NybyUri.groupsHome()}
              icon={<GroupsIcon height={25} />}
              activeIcon={<GroupsActiveIcon height={25} />}
            >
              {requestsToJoinCount > 0 && <S.Badge data-testid="GroupsBadge">{requestsToJoinCount}</S.Badge>}
            </NavButton>
            {showMembersTab && (
              <NavButton
                label={i18n.__('global.units')}
                to={NybyUri.unitsHome()}
                icon={<MembersIcon height={25} />}
                activeIcon={<MembersIcon height={25} />}
              />
            )}
            <NavButton
              label={i18n.__('global.messages')}
              to={NybyUri.messagesHome()}
              icon={<MessagesIcon height={25} />}
              activeIcon={<MessagesActiveIcon height={25} />}
            >
              {newThreadsCount > 0 && <S.Badge data-testid="MessagesBadge">{newThreadsCount}</S.Badge>}
            </NavButton>
          </S.Bar>
        </Col>
        <RightSection />
      </Row>
    </S.Root>
  );
}
