import styled from 'styled-components';

import { colors } from 'Styles/theme';
import { B14 } from 'Styles/Typography';

export const Root = styled(B14())`
  background: ${colors.blue10};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  height: 32px;
  padding: 0 12px;
  margin-right: 10px;
  margin-top: 4px;

  & span {
    padding-right: 8px;
  }

  & svg path {
    fill: white;
  }

  & svg {
    cursor: pointer;
    transition: 0.3s all ease;

    &:hover {
      opacity: 0.7;
    }
  }
`;
