import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Modifier } from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';

import { useOnClickOutside } from 'Hooks/useClickOutside';

import { DropDownClickEvent, IDropDownProps } from './DropDown.types';

/**
 * Close the drop down upon click.
 */
export function closeDropDown(cb: () => any = () => null) {
  return (e: DropDownClickEvent<any>) => {
    // eslint-disable-next-line no-param-reassign
    e.closeDropDown = true;
    cb();
  };
}

export default function useDropDown(props: IDropDownProps) {
  const {
    open = false,
    placement,
    offset,
    openOnClick,
    closeOnClick,
    closeOnClickOutside,
    onOpen,
    onClose,
    update,
    sameWidth,
    onVisibility,
  } = props;
  const [visible, setVisibility] = useState<boolean>(open ?? false);
  const [referenceRef, setReferenceRef] = useState(null);
  const [popperRef, setPopperRef] = useState<any>(null);
  const [arrowRef, setArrowRef] = useState(null);
  useOnClickOutside(popperRef, referenceRef, visible, () => closeOnClickOutside && setVisibility(false));

  useEffect(() => {
    setVisibility(open);
    if (open && onOpen) {
      onOpen();
    }
  }, [open]);

  useEffect(() => {
    if (onVisibility) {
      onVisibility(visible);
    }
  }, [visible]);

  const customModifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'matchReferenceSize',
        enabled: !!sameWidth,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';

          if (!popperRef) return;

          const popperSize =
            popperRef[`offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.round(popperSize) === Math.round(referenceSize)) return;

          popperRef.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({ state }) {
          const { height } = state.modifiersData.maxSize;
          state.styles.popper.maxHeight = `${height}px`;
        },
      },
    ],
    [popperRef]
  );

  const modifiers = [
    maxSize,
    { name: 'offset', enabled: true, options: { offset } },
    { name: 'flip', enabled: true },
    { name: 'arrow', options: { element: arrowRef, padding: 5 } },
    ...customModifiers,
  ];

  const {
    styles,
    attributes,
    forceUpdate,
    update: updatePopper,
  } = usePopper(referenceRef, popperRef, {
    placement,
    modifiers,
  });

  useEffect(() => {
    if (updatePopper) {
      updatePopper().then();
    }
  }, [update]);

  const handleDropDownClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (!visible && openOnClick) {
      setVisibility(true);
      if (onOpen) {
        onOpen();
      }
    } else if (visible) {
      setVisibility(false);
      if (onClose) {
        onClose();
      }
    }

    if (!visible) {
      // not sure why, need to trigger update after render maybe?
      setTimeout(() => {
        if (forceUpdate) {
          forceUpdate();
        }
      }, 1);
    }
  };

  // children onClick handlers can set event.closeDropDown = true to close the drop down
  // this works because events on children are processed first then bubbled up to this parent
  const onItemClick = (e: DropDownClickEvent<any>) => {
    e.stopPropagation();
    if (closeOnClick || e.closeDropDown) {
      setVisibility(false);
    }
  };

  return {
    visible,
    handleDropDownClick,
    setReferenceRef,
    referenceRef,
    setPopperRef,
    setArrowRef,
    styles,
    attributes,
    setVisibility,
    onItemClick,
    forceUpdate,
  };
}
