import { CSSProperties } from 'react';
import { createPortal } from 'react-dom';

import WhiteSpinner from 'Assets/png/loading-white.png';
import BlueSpinner from 'Assets/png/loading-blue.png';

import * as S from './Loading.styles';

type ILoadingProps = {
  light?: boolean;
  transparent?: boolean;
  inline?: boolean;
  style?: CSSProperties;
  spinnerStyle?: CSSProperties;
};

const Loading = (props: ILoadingProps) => {
  const { light = false, transparent = false, inline = false, style = {}, spinnerStyle = {}, ...restProps } = props;

  const renderContainer = () => (
    <S.Root inline={inline} light={light} style={style} transparent={transparent} {...restProps}>
      {!light && <S.Spinner src={WhiteSpinner} style={spinnerStyle} />}
      {light && <S.Spinner src={BlueSpinner} style={spinnerStyle} />}
    </S.Root>
  );

  if (inline) {
    return renderContainer();
  }

  const mainContainer = document.getElementById('root') as HTMLElement;
  return createPortal(renderContainer(), mainContainer);
};

export default Loading;
