import { useParams } from 'react-router-dom';
import { IUserSession, MeteorCall, saveUserToken } from 'API/Meteor';
import { useNavigate } from 'react-router';
import Loading from 'Interface/Loading';

export default function TestUserLogin() {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  MeteorCall<IUserSession>('autoLoginTestUser', { userId }).then((result) => {
    if (!result.error) {
      saveUserToken(result);
      navigate('/home');
    } else {
      alert(result.error);
    }
  });
  return <Loading light transparent />;
}
