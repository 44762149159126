import { DynamicModuleLoader } from 'redux-dynamic-modules';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import Loading from 'Interface/Loading';
import { PRELOADING_DELAY } from 'Constants/app';
import { reducers } from './reducers';

const Groups = loadable(() => pMinDelay(import('./components'), PRELOADING_DELAY), {
  fallback: <Loading light />,
});

export default () => (
  <DynamicModuleLoader modules={[reducers()]}>
    <Groups />
  </DynamicModuleLoader>
);
