const COLLECTIONS = {
  auditLog: 'auditLog',
  badges: 'badges',
  channelsFaces: 'board-template-faces',
  channels: 'boardTemplates',
  channelSingle: 'boardTemplateSingle',
  channelsList: 'boardTemplatesList',
  channelsShare: 'boardTemplatesShare',
  boards: 'boards',
  campaignJoins: 'campaignJoins',
  campaigns: 'campaigns',
  threads: 'channels',
  contacts: 'contacts',
  counters: 'counters',
  cronHistory: 'cronHistory',
  datadog: 'datadog',
  deletedUsers: 'deletedUsers',
  emails: 'emails',
  eventParticipants: 'eventParticipants',
  events: 'events',
  follows: 'follows',
  invitations: 'invitations',
  jobs: 'jobs',
  messages: 'messages',
  migrations: 'migrations',
  mixpanelPending: 'mixpanelPending',
  mobileSmsLog: 'mobile-sms-log',
  notifications: 'notifications',
  onConnectionStats: 'onConnectionStats',
  perfData: 'perfData',
  requestFaces: 'post-faces',
  requestReports: 'postReports',
  requestSuggestionsLog: 'postSuggestionsLog',
  requests: 'posts',
  requestsAuto: 'postsAuto',
  requestAutoLog: 'postsAutoLog',
  roles: 'roles',
  scheduler: 'scheduler',
  tagBags: 'tagBags',
  userLists: 'tags',
  tasks: 'tasks',
  groupMembersInvite: 'teamMembersInvite',
  groups: 'teams',
  groupAccounts: 'teamsAccounts',
  temp: 'temp',
  tempUsers: 'tempUsers',
  userAdminInvite: 'userAdminInvite',
  userBlocks: 'userBlocks',
  userCategories: 'userCategories',
  userNotes: 'userNotes',
  userSettings: 'userSettings',
  userTagHistory: 'userTagHistory',
  users: 'users',
  AggregatePagination: 'AggregatePagination',
  progress: 'progress',
  requestsExport: 'requestsExport',
  requestsGraphPublish: 'posts-graph-publish',
  groupsNewMembers: 'teamsNewMembers',
  groupsTotal: 'teamsTotal',
  groupsMembers: 'teamsMembers',
  members: 'members',
  admins: 'admins',
  wishList: 'wishList',
  notices: 'notices',
  userBadges: 'userBadges',
  profiles: 'profiles',
  channelsRecipients: 'board-template-recipients',
  units: 'teams',
};

export default COLLECTIONS;
