export default {
  unitsHome() {
    return '/units';
  },

  unitMembers(unitId: string) {
    return `/units/${unitId}`;
  },

  unitAdmins(unitId: string) {
    return `/units/${unitId}?filter=ADMINS`;
  },

  unitSettings(unitId: string) {
    return `/units/${unitId}/settings`;
  },

  unitMember(unitId: string, memberId: string) {
    return `/units/${unitId}/member/${memberId}`;
  },
};
