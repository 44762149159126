import { createStore, IModule, IModuleStore } from 'redux-dynamic-modules';
import { AnyAction } from 'redux';
// import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import coreReducers, { ICoreModuleState } from 'Modules/core/reducers';
import { initialState as modalInitialState } from 'Modules/core/reducers/modal';
import { initialState as notificationInitialState } from 'Modules/core/reducers/notificationBar';
import { initialState as badgesInitialState } from 'Modules/core/reducers/badges';
import { initialState as bucketsInitialState } from 'Modules/core/reducers/buckets';

export type IAppState = ICoreModuleState;

const initialState: IAppState = {
  modal: modalInitialState,
  notificationBar: notificationInitialState,
  badges: badgesInitialState,
  buckets: bucketsInitialState,
};

export default class Store {
  static store: IModuleStore<IAppState>;

  static createStore(state = initialState) {
    const coreModule: IModule<ICoreModuleState> = {
      id: 'core',
      reducerMap: coreReducers,
    };

    Store.store = createStore(
      {
        initialState: state,
        extensions: [
          /* getThunkExtension() */
        ],
      },
      coreModule
    );

    return Store.store;
  }

  static getState() {
    return Store.store?.getState();
  }

  static dispatch(action: AnyAction) {
    Store.store?.dispatch(action);
  }
}
